
import React from "react";
import { sortArrayByGrade } from "../Services/AppManager";


const sectorAllDict = {

    label: 'All',
    Name: 'All',
    value: 'All',
    key: 'sector'
}

const momentumAllDict = {

    label: 'All',
    Name: 'All',
    value: 'All',
    'key': 'momentomGrd'

}

export default class DynamicFilterOption extends React.Component {

    sectorFilterOption = []
    momentumFilterOptions = []

    static myInstance = null;
    static getInstance() {

        if (this.myInstance === null) {
            this.sectorFilterOption = []
            this.momentumFilterOptions = []
            this.myInstance = new DynamicFilterOption();
        }
        return this.myInstance;
    }


    setSectionOptions = (keys) => {
        this.sectorFilterOption = [sectorAllDict]
        keys.map(key => {

            let dict = {
                'label': key,
                'Name': key,
                'value': key,
                'key': 'sector',
            }
            this.sectorFilterOption.push(dict)
        })

        // console.log('sectorFilterOption', this.sectorFilterOption);
    }

    setMomentumOptions = (keys) => {

        this.momentumFilterOptions = []
        keys.map(key => {

            let dict = {
                'label': `Momentum Grade ${key}`,
                'Name': `Momentum Grade ${key}`,
                'value': `Momentum Grade ${key}`,
                'key': 'momentomGrd',
                'data': key,
            }
            this.momentumFilterOptions.push(dict)
        })
        this.momentumFilterOptions.sort(sortArrayByGrade('data'))
        this.momentumFilterOptions.splice(0, 0, momentumAllDict)
    }


}