
//  Default Colors

export const WHITE='white'
export const LIGHT_GRAY='#ecebed'
export const TEXT_GRAY='#808080'
export const GRAY='#d7dcd6'
export const TEXT_COLOR='#dbdbdb'
export const DARK_GRAY='#9e9e9e'
export const BLUE='#78adfe'
export const INACTIVE_TAB='#99d8a3'
export const TRANSPIRENT='#f7f7f7'
export const EXTRA_DARK_GRAY='#2f302f'

//  BSN Colors

export const APP_COLOR1='#007aff' // Primary Blue Color
export const APP_COLOR_BACKGROUND='#F7F9FB'
export const APP_COLOR_BORDER='#EFEFEF'
export const APP_DARK_GARY ='#515253'
export const APP_COLOR__Input_BORDER='rgba(191,193,203,0.1)'

export const TABLE_CELL_BG ='rgba(191,193,203,0.15)'
export const TABLE_CELL_TEXT ='#A5A5A5'

export const MAIN_TITLE_COLOR ='#131313'

export const SELECTED_VIEW_BG ='rgba(41, 150, 255, 0.10)'


















