import { createStore, combineReducers, applyMiddleware } from 'redux';
import stocksReducers from './reducers/stocksReducers';
import stockOperationReducers from './reducers/stockOperationReducers';

import reduxThunk from 'redux-thunk';
const rootReducer = combineReducers({
    stocksReducers: stocksReducers,
    stockOperationReducers: stockOperationReducers
})

const configStore = () => createStore(rootReducer, applyMiddleware(reduxThunk))
export default configStore;
