import { STORE_FAVOURITE, ADD_FAVOURITE, ADD_FAV_STOCKS, FAVOURITE_HISTORY, SUBSCRIPTION_HISTORY, CLEAR_ALL_DATA, CRON_JOB_TIME, SECTOR_FILTER, MOMENTUM_FILTER } from '../actions/types';
import Constant from '../../../Utils/Constant';

const initialState = {
    favList: [],
    favStockList: [],
    favStockDetail: [],
    subscriptionHistory: [],
    cronJobTimeUTC: '',

}

const stockOperationReducers = (state = initialState, actions) => {
    switch (actions.type) {

        case STORE_FAVOURITE:
            return {
                ...state,
                favList: actions.payload
            }

        case ADD_FAVOURITE:
            var totalFavLIst = state.favList
            totalFavLIst.push(actions.payload)
            return {
                ...state,
                favList: totalFavLIst
            }

        case ADD_FAV_STOCKS:
            return {
                ...state,
                favStockList: actions.payload
            }

        case FAVOURITE_HISTORY:
            return {
                ...state,
                favStockDetail: actions.payload
            }

        case SUBSCRIPTION_HISTORY:
            return {
                ...state,
                subscriptionHistory: actions.payload
            }

        case CLEAR_ALL_DATA:
            return {
                ...state,
                favList: [],
                favStockList: [],
                favStockDetail: [],
                subscriptionHistory: []

            }

        case CRON_JOB_TIME:

            return {
                ...state,
                cronJobTimeUTC: actions.payload
            }

        default:
            // console.log(state);
            return state;
    }
}

export default stockOperationReducers;