
import * as React from 'react';
import { View, Text, StyleSheet, Platform, TouchableOpacity, ScrollView, TextInput } from 'react-native';

import Constant from '../../../Utils/Constant';
import * as Colors from '../../../Utils/Colors';
import MyStorage from '../../../Services/MyStorage';
import { showFlashBanner } from '../../../Utils/FlashMessage';
import Dialog from '@material-ui/core/Dialog';
import CircularProgress from '@material-ui/core/CircularProgress';
import { checkValidation } from '../../../Services/AppManager';

import ApiManager from '../../../Services/Networking/ApiManager';
import { ActivityIndicator } from 'react-native-paper';
let apiManager = ApiManager.getInstance()

class EmailExistsPopup extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isPopupShowing: false,
            email: '',
            isLoader: false
        }

    }

    handlePopupCreate = () => {


        let emailStatus = checkValidation('email', this.state.email, 'Email')
        if (!emailStatus.status) {
            showFlashBanner(emailStatus.messsage, '1')
            return;
        }

        this.setState({
            isLoader: true
        })

        let dict = {
            userId: MyStorage.getItem(Constant.ShareInstanceKey.kUserID),
            email: this.state.email,
        }
        apiManager.onPostApi(Constant.EndPoint.changeEmail, dict).then((responce) => {

            this.setState({
                isLoader: false
            })
            if (responce.json.status == 200) {
                window.location.href = window.location.origin;;
            } else {
                showFlashBanner(responce.json.message, '1')
            }

        }).catch((error) => {
            console.log(error.msg);
            this.setState({
                isLoader: false
            })
        })

    }

    render() {

        return (
            <View style={{}}>
                <Dialog aria-labelledby="customized-dialog-title" open={true}>
                    <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                        <View style={styles.Container}>
                            <View style={styles.Body}>
                                <Text style={styles.Body_Text}>
                                    Enter Email
                                </Text>
                                <TextInput
                                    placeholder={'Email'}
                                    style={styles.Body_Input}
                                    onChangeText={(text) => this.setState({ email: text })}
                                />

                                {this.state.isLoader &&
                                    <View  style={[styles.Button_2,{marginTop: 40, backgroundColor:'white'}]} >
                                        <CircularProgress />
                                    </View>
                                }

                                {!this.state.isLoader &&
                                    <TouchableOpacity onPress={() => this.handlePopupCreate()} style={styles.Button_2} >
                                        <Text style={styles.ButtonText_2}>SAVE</Text>
                                    </TouchableOpacity>
                                }

                            </View>
                        </View>
                    </View>
                </Dialog>
            </View>
        );
    }
}


export default EmailExistsPopup;

const styles = StyleSheet.create({

    Container: {
        width: 300,
        height: 230,
        backgroundColor: 'white',
        justifyContent: 'space-between',
        borderRadius: 10,

    },
    Body: {
        paddingHorizontal: 20,
        paddingVertical: 20
    },

    Body_Text: {
        fontSize: 25,
        paddingTop: 15,
        fontFamily: Constant.AppFonts.Roboto.Medium,
        fontWeight: Constant.AppFontWeight.MEDIUM,
    },

    Body_Input: {
        borderWidth: 1.5,
        paddingVertical: 10,
        paddingHorizontal: 10,
        marginTop: 15,
        borderRadius: 5,
        borderColor: 'rgba(0,0,0,0.2)',
        fontFamily: Constant.AppFonts.Roboto.Medium,
        fontWeight: Constant.AppFontWeight.MEDIUM,
    },

    Button_2: {

        backgroundColor: Colors.APP_COLOR1,
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 40,
        height: 40,
        borderRadius: 10

    },
    ButtonText_2: {
        color: 'white',
        fontSize: 13,
        fontFamily: Constant.AppFonts.Roboto.Medium,
        fontWeight: Constant.AppFontWeight.MEDIUM,

    }

});