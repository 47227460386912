import React from 'react';
import { View, Text, TouchableOpacity, Image, StyleSheet } from 'react-native';
import { ListItem, CheckBox } from 'react-native-elements';
import { RadioButton } from 'react-native-paper';
import { fontSizeScale, sortArrayByKey } from '../../Services/AppManager';
import RBSheet from "react-native-raw-bottom-sheet";
import { connect } from 'react-redux';
import { addStockData } from '../../Services/Redux/actions/stocksAction';
import Contents from '../../Utils/Constant';
import  MaterialCommunityIcons  from 'react-native-vector-icons/MaterialCommunityIcons';
import * as Colors from '../../Utils/Colors';

const ASC = 0
const DESC = 1
const PRICE_HIGH = 2
const PRICE_LOW = 3
const RANK_HIGH = 4
const RANK_LOW = 5


const list = [
    {
        name: 'Name-- A to Z(Ascending)',
        value: ASC
    },
    {
        name: 'Name-- Z to A(Descending)',
        value: DESC
    },
    {
        name: 'Price--Low to High',
        value: PRICE_HIGH
    },
    {
        name: 'Price--High to Low',
        value: PRICE_LOW
    },
    {
        name: 'Rank--Low to High',
        value: RANK_HIGH
    },
    {
        name: 'Rank--High to Low',
        value: RANK_LOW
    },

]


class SortBottomPopup extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            checked: ASC
        }
    }

    onTapSortButton = () => {

        this.RBSheet.open()
    }

    getKeyForSorting = (index) => {

        switch (index) {
            case 0:
                return 'sym'
            case 1:
                return 'sym'
            case 2:
                return 'lastClosingPrice'
            case 3:
                return 'lastClosingPrice'
            case 4:
                return 'ranking'
            case 5:
                return 'ranking'
            default:
                return ''
        }

    }

    onSelectOption = (selectedType) => {

        this.RBSheet.close()

        var sortedType = 'asc'
        if (selectedType == DESC || selectedType == PRICE_LOW || selectedType == RANK_LOW) {
            sortedType = 'desc'
        }

        let currentShowingData = this.props.stockData
        let sortedData = currentShowingData.sort(sortArrayByKey(this.getKeyForSorting(selectedType), sortedType))
        this.props.addStockData(sortedData)
        this.setState({
            checked: selectedType
        });
    }

    render() {

        return (
            <View>
                <TouchableOpacity onPress={() => this.onTapSortButton()} activeOpacity={0.5}>
                    {/* <Image
                        source={require("../../../assets/sorting.png")}
                        style={{ width: fontSizeScale(30), height: fontSizeScale(25) }}
                        resizeMode={'contain'}
                    /> */}
                    <MaterialCommunityIcons name={'sort'} color={Colors.APP_COLOR1} size={fontSizeScale(25)} />
                </TouchableOpacity>

                <View>
                    <RBSheet
                        ref={ref => {
                            this.RBSheet = ref;
                        }}
                        height={fontSizeScale(390)}
                        openDuration={fontSizeScale(250)}
                        customStyles={{
                            container: {

                            }
                        }}
                    >

                        <View style={styles.bottomNavigationView}>

                            <View style={{ marginTop: fontSizeScale(20), marginLeft: fontSizeScale(15), marginRight: fontSizeScale(15) }}>
                                <Text style={{ fontSize: fontSizeScale(18), color: '#2996FF', fontFamily: Contents.AppFonts.Roboto.Medium }}>SORT BY </Text>
                                <View style={{ marginTop: fontSizeScale(10), height: fontSizeScale(2), backgroundColor: '#E2E9FF' }} />

                            </View>


                            {
                                list.map((l, i) => (
                                    <ListItem key={i} onPress={() => this.onSelectOption(l.value)} containerStyle={{ paddingHorizontal: fontSizeScale(15), }} >
                                        <ListItem.Content containerStyle={{}}>
                                            <ListItem.Title style={{ fontFamily: Contents.AppFonts.Roboto.Regular }}>{l.name}</ListItem.Title>
                                        </ListItem.Content>
                                        <View style={{ height: fontSizeScale(25), width: fontSizeScale(25), marginRight: fontSizeScale(10) }}>
                                            <CheckBox
                                                containerStyle={{ padding: fontSizeScale(0), margin: fontSizeScale(0), }}
                                                title=''
                                                checkedIcon='dot-circle-o'
                                                uncheckedIcon='circle-o'
                                                checked={this.state.checked == l.value ? true : false}
                                                onPress={() => this.onSelectOption(l.value)}
                                            />

                                        </View>

                                    </ListItem>
                                ))
                            }
                        </View>
                    </RBSheet>
                </View>
            </View >
        );
    }
}

const mapStateToProps = (state) => {
    return {
        stockData: state.stocksReducers.stockData,
        selectedSection: state.stocksReducers.selectedSection,
        selectedSectionObj: state.stocksReducers.selectedSubSection,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        addStockData: (sortedData) => dispatch(addStockData(sortedData))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SortBottomPopup);


const styles = StyleSheet.create({

    bottomNavigationView: {
        backgroundColor: '#fff',
        height: fontSizeScale(390),

    },

    sheet: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: fontSizeScale(5)
    },

});