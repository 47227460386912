import React from 'react';
import MainRoot from './InitalRoute/MainRoot';

export default class App extends React.Component {
    render() {
        return (
                <MainRoot />
        );
    }
}
