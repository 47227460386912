

export default class FavouriteListModel {
    constructor(

        createdAt = '',
        favListid = '',
        name = '',
        userId = ''
    ) {
        this.createdAt = createdAt,
            this.favListid = favListid,
            this.name = name,
            this.userId = userId
    }

    init(favList) {

        this.createdAt = favList.created_at,
            this.favListid = favList.id,
            this.name = favList.name,
            this.userId = favList.user_id

        return this
    }
}