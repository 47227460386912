

export const getSingleRowValue = (cellItem, responceData) => {

    switch (cellItem.rowValueKey) {

        case 'PEG Ratio': {
            if (responceData.stock_details[0]['yr5_grw_rt'] > 0) {
                let peg = responceData.stock_details[0]['fwd_pe'] / responceData.stock_details[0]['yr5_grw_rt'];
                return peg
            }
            return 'N/A'
        }

        case 'new_5yr_trg_prc': {
            if (responceData.stock_details[0]['new_5yr_trg_prc'] > 0) {
                return responceData.stock_details[0]['new_5yr_trg_prc']
            }
            if (responceData.stock_details[0]['yr5_trg_prc'] > 0) {
                return responceData.stock_details[0]['yr5_trg_prc']
            }
            return 'N/A'
        }

        case 'pot_ret': {
            if (responceData.stock_details[0]['avg_est_nxt_yr'] > 0 && responceData.stock_details[0]['yr5_trg_prc'] > 0) {
                return responceData.stock_details[0]['pot_ret']
            }
            return 'N/A'
        }

        case 'rank': {
            if (responceData.stock_rank[0].rank > 0) {
                return responceData.stock_rank[0].rank + ' / ' + responceData.totol_stock[0].tot_stk
            }
            return 'N/A'
        }

        case 'val_grd': {
            if (responceData.stock_details[0]['yr5_trg_prc'] > 0) {
                return responceData.stock_details[0]['val_grd']
            }
            return 'N/A'
        }

        case 'gcm_grd': {
            if (responceData.stock_details[0]['prc_1yr_ago'] > 0) {
                return responceData.stock_details[0]['gcm_grd']
            }
            return 'N/A'
        }

        case 'rank_1':
        case 'rank_7':
        case 'rank_1_mon':
        case 'rank_2_mon':
        case 'rank_3_mon':
        case 'rank_6_mon':
        case 'rank_9_mon':
        case 'rank_12_mon':
        case 'rank_18_mon':
            return responceData.stock_rank[0][cellItem.rowValueKey]
        case 'avg_est_nxt_yr': {
            if (responceData.stock_details[0][cellItem.rowValueKey] > 0) {
                return responceData.stock_details[0][cellItem.rowValueKey]
            }
            return 'N/A'
        }

        default:

            let value = responceData.stock_details[0][cellItem.rowValueKey] || 'N/A'
            return value
    }
}


export const getOptionalKey = (key) => {

    switch (key) {
        case 'prc_1mo_ago':
            return 'mon1_tot_ret'
        case 'prc_3mo_ago':
            return 'mon3_tot_ret'
        case 'prc_1yr_ago':
            return 'yr1_tot_ret'

        default:
            break;
    }

}



export const getRowValueWithNumber = (cellItem, responceData, rowNumber) => {

    switch (cellItem.rowValueKey) {

        case 'prc_1mo_ago':
        case 'prc_3mo_ago':
        case 'prc_1yr_ago':
            if (responceData.stock_details[0][cellItem.rowValueKey] > 0) {

                var val = (responceData.stock_details[0].prc - responceData.stock_details[0][cellItem.rowValueKey]) / responceData.stock_details[0][cellItem.rowValueKey]
                val = val * 100
                val = val.toFixed(1);                
                return rowNumber == 1 ? val : parseFloat(responceData.topsp5[0][(getOptionalKey(cellItem.rowValueKey))]).toFixed(1)
            }
            return rowNumber == 1 ? 'N/A' : parseFloat(responceData.topsp5[0][(getOptionalKey(cellItem.rowValueKey))]).toFixed(1)

        case 'mtm_grd': {
            if (responceData.stock_details[0].prc_1yr_ago > 0) {
                return rowNumber == 1 ? responceData.stock_details[0].mtm_grd : 'C'
            }
            return rowNumber == 1 ? 'N/A' : 'C'
        }

        case 'prc_3yr_ago': {

            if (responceData.stock_details[0].prc_3yr_ago == 0) {
                return 'N/A'
            }
            if (rowNumber == 1) {
                var ThreeYearAgo = responceData.stock_details[0].prc / responceData.stock_details[0].prc_3yr_ago
                ThreeYearAgo = Math.pow(ThreeYearAgo + 1, 0.3333)
                ThreeYearAgo = (ThreeYearAgo - 1) * 100
                ThreeYearAgo = ThreeYearAgo.toFixed(1);
                return ThreeYearAgo
            }
            return parseFloat(responceData.topsp5[0].yr3_avg_tot_ret).toFixed(1)
        }

        case 'prc_5yr_ago': {

            if (responceData.stock_details[0].prc_5yr_ago > 0) {
                var FiveYearAgo = responceData.stock_details[0].prc / responceData.stock_details[0].prc_5yr_ago
                FiveYearAgo = Math.pow(FiveYearAgo + 1, 0.2)
                FiveYearAgo = (FiveYearAgo - 1) * 100
                FiveYearAgo = FiveYearAgo.toFixed(1);
                return rowNumber == 1 ? FiveYearAgo : parseFloat(responceData.topsp5[0].yr5_avg_tot_ret).toFixed(1)
            }
            return rowNumber == 1 ? 'N/A' : parseFloat(responceData.topsp5[0].yr5_avg_tot_ret).toFixed(1)

        }

        case 'prc_10yr_ago': {

            if (responceData.stock_details[0].prc_10yr_ago > 0) {
                var TenYearAgo = responceData.stock_details[0].prc / responceData.stock_details[0].prc_10yr_ago
                TenYearAgo = Math.pow(TenYearAgo + 1, 0.1)
                TenYearAgo = (TenYearAgo - 1) * 100
                TenYearAgo = TenYearAgo.toFixed(1);
                return rowNumber == 1 ? TenYearAgo : parseFloat(responceData.topsp5[0].yr10_avg_tot_ret).toFixed(1)
            }
            return rowNumber == 1 ? 'N/A' : parseFloat(responceData.topsp5[0].yr10_avg_tot_ret).toFixed(1)

        }

        case 'sft_grd':
        case 'pre_grd': {
            if (responceData.stock_details[0].prc_10yr_ago > 0) {
                return rowNumber == 1 ? responceData.stock_details[0][cellItem.rowValueKey] : 'C'
            }
            return rowNumber == 1 ? 'N/A' : 'C'
        }

        case 'adj_cls_20071011': {

            if ((responceData.stock_details[0].adj_cls_20071011 > 0) && (responceData.stock_details[0].adj_cls_20090306 > 0)) {
                var AdjCls = (responceData.stock_details[0].adj_cls_20090306 - responceData.stock_details[0].adj_cls_20071011) / responceData.stock_details[0].adj_cls_20071011
                AdjCls = AdjCls * 100
                AdjCls = AdjCls.toFixed(1);
                return rowNumber == 1 ? AdjCls : '-54.5'
            }
            return rowNumber == 1 ? 'N/A' : '-54.5'
        }

        case 'cls_prc_20201231': {

            var ClsPrc = (responceData.topsp5[0].prc - responceData.topsp5[0].cls_prc_20201231) / responceData.topsp5[0].cls_prc_20201231
            ClsPrc = ClsPrc * 100
            ClsPrc = ClsPrc.toFixed(1)

            if (responceData.stock_details[0].cls_prc_20201231 == 0) {
                return rowNumber == 1 ? 'N/A' : ClsPrc
            }

            var prc2 = (responceData.stock_details[0].prc - responceData.stock_details[0].cls_prc_20201231) / responceData.stock_details[0].cls_prc_20201231
            prc2 = prc2 * 100
            prc2 = prc2.toFixed(1)
            return rowNumber == 1 ? prc2 : ClsPrc
        }

        case 'last_ten_year_performance_key': {
                        
            return rowNumber == 1 ? (responceData.grade_data[cellItem.get1stValueKey] || 'N/A') : (responceData.grade_data[cellItem.getSecValueKey] || 'N/A')
        }


        case 'ret_2020': {
            if (responceData.stock_details[0].ret_2020 == 0) {
                return rowNumber == 1 ? 'N/A' : '16.7'
            }
            return rowNumber == 1 ? parseFloat(responceData.stock_details[0].ret_2020).toFixed(1) : '16.7'
        }

        case 'ret_2019': {
            if (responceData.stock_details[0].ret_2019 == 0) {
                return rowNumber == 1 ? 'N/A' : '28.9'
            }
            return rowNumber == 1 ? parseFloat(responceData.stock_details[0].ret_2019).toFixed(1) : '28.9'
        }

        case 'ret_2018': {
            if (responceData.stock_details[0].ret_2018 == 0) {
                return rowNumber == 1 ? 'N/A' : '-6.2'
            }
            return rowNumber == 1 ? parseFloat(responceData.stock_details[0].ret_2018).toFixed(1) : '-6.2'
        }

        case 'ret_2017': {
            if (responceData.stock_details[0].ret_2017 == 0) {
                return rowNumber == 1 ? 'N/A' : '19.4'
            }
            return rowNumber == 1 ? parseFloat(responceData.stock_details[0].ret_2017).toFixed(1) : '19.4'
        }

        case 'ret_2016': {
            if (responceData.stock_details[0].ret_2016 == 0) {
                return rowNumber == 1 ? 'N/A' : '9.5'
            }
            return rowNumber == 1 ? parseFloat(responceData.stock_details[0].ret_2016).toFixed(1) : '9.5'
        }

        case 'ret_2015': {
            if (responceData.stock_details[0].ret_2015 == 0) {
                return rowNumber == 1 ? 'N/A' : '-0.7'
            }
            return rowNumber == 1 ? parseFloat(responceData.stock_details[0].ret_2015).toFixed(1) : '-0.7'
        }

        case 'ret_2014': {
            if (responceData.stock_details[0].ret_2014 == 0) {
                return rowNumber == 1 ? 'N/A' : '11.4'
            }
            return rowNumber == 1 ? parseFloat(responceData.stock_details[0].ret_2014).toFixed(1) : '11.4'
        }

        case 'ret_2013': {
            if (responceData.stock_details[0].ret_2013 == 0) {
                return rowNumber == 1 ? 'N/A' : '22.8'
            }
            return rowNumber == 1 ? parseFloat(responceData.stock_details[0].ret_2013).toFixed(1) : '22.8'
        }

        case 'ret_2012': {
            if (responceData.stock_details[0].ret_2012 == 0) {
                return rowNumber == 1 ? 'N/A' : '11.8'
            }
            return rowNumber == 1 ? parseFloat(responceData.stock_details[0].ret_2012).toFixed(1) : '11.8'
        }
        default:
            return 'N/A'
    }
}