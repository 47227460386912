
import * as React from 'react';
import { View, Text, StyleSheet, Platform, TouchableOpacity, ScrollView, TextInput } from 'react-native';

import { connect } from 'react-redux';
import { createFavList, favStockDetails, addStockInFav } from '../../../Services/Redux/actions/stocksAction';
import { Popover, OverlayTrigger, Button } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import Constant from '../../../Utils/Constant';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons'
import { ListItem } from 'react-native-elements';
import * as Colors from '../../../Utils/Colors';
import MyStorage from '../../../Services/MyStorage';
import FavouriteListModel from '../../../Models/FavouriteListModel';
import FavouriteStocksListModel from '../../../Models/FavouriteListModel';
import { showFlashBanner } from '../../../Utils/FlashMessage';
import Dialog from '@material-ui/core/Dialog';


class FavoriteListPopup extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isPopupShowing: false,
            openCreateListPopup: false,
            listName: ''
        }

    }

    onClickCell = (item) => {

        console.log(item);

        let dict = {
            symkey: this.props.symbol,
            listId: item.favListid,
            userId: MyStorage.getItem(Constant.ShareInstanceKey.kUserID),
            name: item.name
        }

        if (item.userId == 0) {
            // Create

            this.setState({
                openCreateListPopup: true
            })

        } else if (item.userId == -1) {
            // Remove
            this.updateFavStock(dict, 'remove')

        } else {
            // Add
            this.updateFavStock(dict, 'add')
        }

    }

    handlePopupCreate = () => {

        if (this.state.listName != '') {
            let dict = {
                listName: this.state.listName,
                userId: MyStorage.getItem(Constant.ShareInstanceKey.kUserID)
            }
            this.setState({
                openCreateListPopup: false,
                listName: '',
            })

            this.props.createFavList(dict).then((abc) => {
                // showFlashBanner(abc.json.message, '0')
                console.log('Created', abc);

                let dict = {
                    symkey: this.props.symbol,
                    listId: abc.json.data.id,
                    userId: MyStorage.getItem(Constant.ShareInstanceKey.kUserID),
                    name: abc.json.data.name
                }
                this.updateFavStock(dict, 'add')

            });
        }
    }

    updateFavStock = (dict, actionType) => {

        let id = Math.floor((Math.random() * 10) + 1);
        var model = new FavouriteStocksListModel()
        model.favListid = id,
            model.listId = dict.listId,
            model.name = dict.symkey
        model.userId = dict.userId,
            model.favListName = dict.name


        this.props.addStockInFav(dict).then((res) => {
            // showFlashBanner(res.json.message, '0')
            var temp = []
            temp.push(model)
            this.props.favStockDetails(actionType == 'add' ? temp : [])
            // this.setState({
            //     refreshView: this.state.refreshView + 1
            // })

        });

    }


    renderOptions = () => {

        // console.log('this.props.favStockDetail', this.props.favStockDetail);

        if (this.props.favStockDetail.length > 0) {
            let item = {
                name: 'Remove From List',
                userId: -1,
                favListid: this.props.favStockDetail[0].listId
            }
            return (
                <ListItem key={'remove'} onPress={() => this.onClickCell(item)} containerStyle={{}} >
                    <ListItem.Content containerStyle={{}}>
                        <Text style={{ fontFamily: Constant.AppFonts.Roboto.Medium, fontWeight: Constant.AppFontWeight.MEDIUM, size: 12, color: Colors.APP_DARK_GARY }}>Remove From List</Text>
                    </ListItem.Content>
                </ListItem>
            )
        }

        var jsx = this.props.favList.map((item, index) => {
            return (
                <ListItem key={index} onPress={() => this.onClickCell(item)} key={item.id} containerStyle={{}} >
                    <ListItem.Content key={index} containerStyle={{}}>
                        <Text style={{ fontFamily: Constant.AppFonts.Roboto.Medium, fontWeight: Constant.AppFontWeight.MEDIUM, size: 12, color: Colors.APP_DARK_GARY }}>{item.name}</Text>
                    </ListItem.Content>
                </ListItem>
            )
        })

        let dict = {
            name: 'Create New List',
            userId: 0
        }

        jsx.push(
            <ListItem key={'Create'} onPress={() => this.onClickCell(dict)} containerStyle={{}} >
                <ListItem.Content containerStyle={{}}>
                    <Text style={{ fontFamily: Constant.AppFonts.Roboto.Medium, fontWeight: Constant.AppFontWeight.MEDIUM, size: 12, color: Colors.APP_DARK_GARY }}>Create New List</Text>
                </ListItem.Content>
            </ListItem>
        )
        return jsx
    }

    getDropDownText = () => {

        if (this.props.favStockDetail.length > 0) {
            return `Added in ${this.props.favStockDetail[0].favListName}`
        }
        return 'Add to List'

    }


    render() {

        const PopoverStyle = {
            minHeight: '50px',
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
            border: '0px',
        };
        const handleClose = () => {
            this.setState({
                openCreateListPopup: false
            })
        };

        return (
            <View style={{}}>

                <OverlayTrigger
                    trigger='focus'
                    key={'bottom'}
                    placement={'bottom'}
                    delay={200}
                    onToggle={(nextShow) => this.setState({ isPopupShowing: nextShow })}
                    overlay={
                        <Popover style={PopoverStyle} id={`popover_class`}>
                            <Popover.Content style={{ minHeight: '50px', flex: 1, padding: 0 }}>
                                {this.renderOptions()}
                            </Popover.Content>
                        </Popover>
                    }
                >
                    <TouchableOpacity
                        // variant="outline-primary"
                        style={{
                            color: "#b4b4bc",
                            background: "white",
                            borderColor: this.state.isPopupShowing ? Colors.APP_COLOR1 : '#EFEFEF',
                            borderWidth: 2,
                            borderRadius: '5px',
                            fontSize: 14,
                            fontFamily: Constant.AppFonts.Roboto.Medium,
                            fontWeight: Constant.AppFontWeight.MEDIUM,
                            height: 45,
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: 5,
                        }}
                    >
                        <View style={{ justifyContent: 'center', alignItems: 'center', flexDirection: 'row', height: 25 }}>
                            <Text style={{ color: this.state.isPopupShowing ? Colors.APP_COLOR1 : Colors.MAIN_TITLE_COLOR, fontSize: 14, fontFamily: Constant.AppFonts.Roboto.Medium, fontWeight: Constant.AppFontWeight.MEDIUM, }} >{this.getDropDownText()}</Text>
                            <MaterialIcons style={{ marginLeft: 30 }} name={'keyboard-arrow-down'} size={20} color={this.state.isPopupShowing ? Colors.APP_COLOR1 : "#b4b4bc"} />
                        </View>
                    </TouchableOpacity>
                </OverlayTrigger>

                <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={this.state.openCreateListPopup}>

                    <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                        <View style={styles.Container}>
                            <View style={styles.Body}>
                                <Text style={styles.Body_Text}>
                                    List Name
                                </Text>
                                <TextInput
                                    placeholder={'My List 1'}
                                    style={styles.Body_Input}
                                    onChangeText={(text) => this.setState({ listName: text })}
                                />
                            </View>
                            <View style={styles.Container_Button}>
                                <TouchableOpacity onPress={handleClose} style={styles.Button_1}>
                                    <Text style={styles.ButtonText_1}>CANCEL</Text>
                                </TouchableOpacity>

                                <TouchableOpacity onPress={() => this.handlePopupCreate()} style={styles.Button_2} >
                                    <Text style={styles.ButtonText_2}>SAVE</Text>
                                </TouchableOpacity>
                            </View>
                        </View>
                    </View>
                </Dialog>
            </View>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        favList: state.stockOperationReducers.favList,
        favStockDetail: state.stockOperationReducers.favStockDetail
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        createFavList: (request) => dispatch(createFavList(request)),
        favStockDetails: (request) => dispatch(favStockDetails(request)),
        addStockInFav: (request) => dispatch(addStockInFav(request)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FavoriteListPopup);

const styles = StyleSheet.create({

    Container: {
        width: 280,
        height: 210,
        backgroundColor: 'white',
        justifyContent: 'space-between',
        borderRadius: 10,

    },
    Body: {
        paddingHorizontal: 20,
        paddingVertical: 20
    },
    Body_Text: {
        fontSize: 25,
        paddingTop: 15,
        fontFamily: Constant.AppFonts.Roboto.Medium,
        fontWeight: Constant.AppFontWeight.MEDIUM,
    },
    Body_Input: {
        borderWidth: 1.5,
        paddingVertical: 10,
        paddingHorizontal: 10,
        marginTop: 15,
        borderRadius: 5,
        borderColor: 'rgba(0,0,0,0.2)',
        fontFamily: Constant.AppFonts.Roboto.Medium,
        fontWeight: Constant.AppFontWeight.MEDIUM,
        color: Colors.APP_DARK_GARY


    },
    Container_Button: {
        backgroundColor: '#007AFF',
        alignItems: 'center',
        flexDirection: 'row',
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 10
    },
    Button_1: {
        backgroundColor: '#DCDCDC',
        width: '50%',
        alignItems: 'center',
        paddingVertical: 15,
        borderBottomLeftRadius: 5,

    },
    Button_2: {
        backgroundColor: '#007AFF',
        width: '50%',
        alignItems: 'center',
        paddingVertical: 15,
        borderBottomRightRadius: 5,


    },
    ButtonText_1: {

        fontSize: 13,
        fontFamily: Constant.AppFonts.Roboto.Medium,
        fontWeight: Constant.AppFontWeight.MEDIUM,

    },
    ButtonText_2: {
        color: 'white',
        fontSize: 13,
        fontFamily: Constant.AppFonts.Roboto.Medium,
        fontWeight: Constant.AppFontWeight.MEDIUM,

    }

});