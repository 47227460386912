export default class FavouriteStocksListModel {

    constructor(

        createdAt = '',
        favListid = '',
        userId = '',
        listId = '',
        name = '',
        favListName = '',
    ) {
        this.createdAt = createdAt,
            this.favListid = favListid,
            this.userId = userId,
            this.listId = listId,
            this.name = name,
            this.favListName = favListName


    }

    init(favList) {

        this.createdAt = favList.created_at,
            this.favListid = favList.id,
            this.userId = favList.user_id,
            this.listId = favList.list_id,
            this.name = favList.sym
            if(  favList.favourites_list != undefined && favList.favourites_list.length > 0) {
                this.favListName = favList.favourites_list[0]['name']
            }
            


        return this
    }
}