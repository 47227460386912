import * as React from 'react';
import { View, Text, StyleSheet, Image, Platform, ActivityIndicator } from 'react-native';
import Modal from '@material-ui/core/Modal';


class Loader extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {

        return (
            <Modal
                open={true}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <>
                    <ActivityIndicator size={'large'} color={'black'} />
                </>

            </Modal>
        )
    }
}

export default Loader;


