import React from 'react';
import { SafeAreaView, View, StyleSheet, Image, Text, TouchableOpacity, Platform } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { Fontisto } from '@expo/vector-icons';
import { FontAwesome } from '@expo/vector-icons';
import { DrawerContentScrollView, } from '@react-navigation/drawer';
import * as Colors from '../../screens/Utils/Colors';
import { connect } from 'react-redux';
import { saveSection, saveSubSection, addStockData, getFavStocksList, openDrawer, changeSideMenuIndex } from '../../screens/Services/Redux/actions/stocksAction';
import { filterArrayByOptions } from '../../screens/Services/AppManager';
import Constant from '../../screens/Utils/Constant';
import MyStorage from '../../screens/Services/MyStorage';
import DynamicFilterOption from "../../screens/Models/DynamicFilterOption";

let dynamicFilterOption = DynamicFilterOption.getInstance()

class CustomSidebarMenu extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      refesh: 1,
      createListName: '',
      visible: false
    }
  }


  homeButtonTapped = (index) => {

    this.props.openDrawer()
    this.props.changeSideMenuIndex(index)


    // let getOPtion = dynamicFilterOption.sectorFilterOption[0]
    // let filterData = filterArrayByOptions(this.props.stocksModel, getOPtion)
    // this.props.addStockData(filterData)
    // this.props.saveNewSection(Constant.AppText.AllStocks)
    // this.props.saveNewSubSection(getOPtion)
    // this.props.props.navigation.navigate('stocksScreen')

  }

  acountManagerTapped = (index) => {
    let status = MyStorage.getItem(Constant.ShareInstanceKey.kPaymentStatus)
    let newIndex = (parseInt(status) == 1 && this.props.favList.length > 0) ? index + 1 : index
    this.props.openDrawer()
    this.props.changeSideMenuIndex(newIndex)


    // this.props.props.navigation.navigate('authScreen')
  }

  openWebUrl = (index) => {

    this.props.openDrawer()
    let status = MyStorage.getItem(Constant.ShareInstanceKey.kPaymentStatus)
    let newIndex = (parseInt(status) == 1 && this.props.favList.length > 0) ? index + 1 : index
    this.props.changeSideMenuIndex(newIndex)


    return

    if (Platform.OS == 'web') {
      window.open(url, '_blank');
    } else {

      Linking.canOpenURL(url).then((supported) => {
        console.log('Url Support', supported);
        if (supported) {
          return Linking.openURL(url);
        }

      });
    }
  }

  listOptionTapped = () => {

    this.props.openDrawer()
    this.props.changeSideMenuIndex(1)

    // let dict = {
    //   userId: MyStorage.getItem(Constant.ShareInstanceKey.kUserID),
    //   listId: this.props.favList[0].favListid
    // }
    // this.props.getFavStocksList(dict)
    // this.props.saveNewSection(this.props.favList[0].name)
    // this.props.saveNewSubSection(Constant.blankSubSection)
    // this.props.props.navigation.navigate('stocksScreen')

  }

  renderHeader = () => {


    let status = MyStorage.getItem(Constant.ShareInstanceKey.kPaymentStatus)

    return (

      <View style={{ flex: 1, backgroundColor: Colors.APP_COLOR1 }}>
        <View style={styles.image}>
          <Image
            style={{ width: 60, height: 50 }}
            source={require('../../assets/logo.png')}
          />
        </View>

        <TouchableOpacity onPress={() => this.homeButtonTapped(0)} style={styles.row}>
          <Fontisto name="bar-chart" size={20} color="white" />
        </TouchableOpacity>

        {(parseInt(status) == 1 && this.props.favList.length > 0) &&
          <TouchableOpacity onPress={() => this.listOptionTapped()} style={styles.row}>
            <FontAwesome name="list-ul" size={20} color="white" />
          </TouchableOpacity>
        }

        <TouchableOpacity onPress={() => this.openWebUrl(1)} style={styles.row}>
          {/* <Ionicons name="paper-plane-sharp" size={20} color="white" /> */}
          <Image
            source={require('../../assets/web/about.png')}
            resizeMode={'contain'}
            style={{
              width: 22,
              height: 22,
              tintColor: Platform.OS == 'web' ? 'white' : (item.isExpand ? Colors.APP_COLOR1 : Colors.APP_DARK_GARY)
            }} />

        </TouchableOpacity>

        <TouchableOpacity onPress={() => this.openWebUrl(2)} style={styles.row}>
          <FontAwesome name="line-chart" size={20} color="white" />
        </TouchableOpacity>

        <TouchableOpacity onPress={() => this.acountManagerTapped(3)} style={styles.row}>
          <FontAwesome name="user" size={15} color="white" />
        </TouchableOpacity>

        {/* <TouchableOpacity onPress={() => this.openWebUrl('https://www.gundersoncapital.com/')} style={[styles.row, { borderBottomWidth: 0, }]}>
          <Ionicons name="settings-sharp" size={15} color="white" />
        </TouchableOpacity> */}

      </View>
    )

  }

  render() {

    return (
      <View style={{ flex: 1, backgroundColor: Colors.APP_COLOR1, }}>
        <DrawerContentScrollView>
          {this.renderHeader()}
        </DrawerContentScrollView>
      </View >
    );
  }

};

const styles = StyleSheet.create({

  row: {
    borderBottomWidth: 1,
    alignItems: 'center',
    justifyContent: 'center',
    height: 60,
    borderColor: 'rgba(255,255,255,0.1)',
  },

  image: {
    borderBottomWidth: 1,
    borderColor: 'rgba(255,255,255,0.1)',
    alignItems: 'center',
    height: 70,
    marginTop: 15
  }
});

const mapStateToProps = (state) => {
  return {
    favList: state.stockOperationReducers.favList,
    stocksModel: state.stocksReducers.stocksModelData,
    drawerToggle: state.drawerToggle
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    saveNewSection: (sactionName) => dispatch(saveSection(sactionName)),
    saveNewSubSection: (subName) => dispatch(saveSubSection(subName)),
    addStockData: (filterData) => dispatch(addStockData(filterData)),
    getFavStocksList: (req) => dispatch(getFavStocksList(req)),
    openDrawer: () => dispatch(openDrawer()),
    changeSideMenuIndex: (index) => dispatch(changeSideMenuIndex(index)),

  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CustomSidebarMenu);
