
import * as React from 'react';
import { View, Text, StyleSheet, ScrollView } from 'react-native';
import Constant from '../../Utils/Constant';
import * as Colors from '../../Utils/Colors';
import HeaderWeb from '../../Components/WebComponent/HeaderWeb';
import SubscriptionManager from '../../Components/WebComponent/AccountManager/SubscriptionManager';
import SubscriptionView from '../../Components/WebComponent/AccountManager/SubscriptionView';
import MyStorage from '../../Services/MyStorage';


class AccountContainer extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      idRefresh: 0,
    }
  }


  realodPage = () => {

    this.setState({ idRefresh: this.state.idRefresh })

  }


  renderBody = () => {

    console.log('refrsh');

    let userID = MyStorage.getItem(Constant.ShareInstanceKey.kUserID)
    if (userID != '') {
      return (
        <SubscriptionView props={this.props} />
      )
    }
    return (
      <SubscriptionManager props={this.props} onRefresh={this.realodPage.bind()} />
    )

  }

  render() {

    return (

      <View key={'headerContainer'} style={styles.headerContainer}>
        <HeaderWeb props={this.props} pageTitleArray={['Manage Subscription']} />
        <ScrollView key={'mainContainer'} style={styles.mainContainer}>
          {this.renderBody()}
        </ScrollView>
      </View>

    );
  }
}

export default AccountContainer;


const styles = StyleSheet.create({

  headerContainer: {
    flex: 1,
    backgroundColor: Colors.APP_COLOR_BACKGROUND,
  },

  mainContainer: {
    flex: 1,
    backgroundColor: Colors.APP_COLOR_BACKGROUND,
    padding: 30,
    paddingTop: 30,
  },

});
