
import * as React from 'react';
import { View, Text, StyleSheet, ScrollView, TouchableOpacity } from 'react-native';
import Constant from '../../Utils/Constant';
import * as Colors from '../../Utils/Colors';
import HeaderWeb from '../../Components/WebComponent/HeaderWeb';
import FavoriteListPopup from '../../Components/WebComponent/StockDetails/FavoriteListPopup.web';
import StockDetailsSection from '../../Components/WebComponent/StockDetails/StockDetailsSection.web';
import MyStorage from '../../Services/MyStorage';
import { SearchBar } from 'react-native-elements';
import { checkValidation } from '../../Services/AppManager';
import ApiManager from '../../Services/Networking/ApiManager';
let apiManager = ApiManager.getInstance()


class SearchWeb extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            searchText: '',
            selectedSubSection: '',
            staockDetails: {},
            errorMessage: '',
        }
    }

    onSearch = () => {

        let searchStatus = checkValidation('text', this.state.searchText, 'search')
        console.log('searchStatus', searchStatus);
        if (!searchStatus.status) {
            showFlashBanner(searchStatus.messsage, '1')
            return;
        }
        else {
            this.getStockDetails()
        }
    }

    getStockDetails = () => {

        this.setState({ isLoading: true })
        var dict = {
            'symKey': this.state.searchText.toUpperCase(),
        }
        let userID = MyStorage.getItem(Constant.ShareInstanceKey.kUserID)
        if (userID != '') {
            dict['userId'] = userID
        }
        apiManager.onGetApi(Constant.EndPoint.getStockDetail, dict).then((responce) => {
            console.log(responce);
            this.setState({ isLoading: false })
            // this.setState({ staockDetails: responce.json })
            if (responce.json.status === 201) {
                this.setState({ errorMessage: Constant.AppText.errorMsg201 })
            } else {

                console.log(responce.json.data);
                if (responce.json.data.length == 0) {
                    this.setState({ errorMessage: Constant.AppText.errorMsg200 })
                } else {
                    this.setState({
                        errorMessage: '',
                        staockDetails: responce.json.data
                    })
                }
            }
        }).catch((error) => {
            this.setState({ isLoading: false })
        })

    }


    render() {

        return (

            <View key={'headerContainer'} style={styles.headerContainer}>
                <HeaderWeb props={this.props} pageTitleArray={['Search stock by symbol']} />
                <View style={{ alignItems: 'center', flex: 1, }}>
                    <View style={{ height: 45, width: '85%', marginTop: 20, flexDirection: 'row' }}>

                        <SearchBar
                            placeholder={'Search stock by symbol'}
                            searchIcon={{ color: '#c1c1c1' }}
                            inputStyle={[styles.webInputStyle, { outline: 'none', }]}
                            platform="ios"
                            containerStyle={styles.webContainerStyle}
                            inputContainerStyle={styles.webInputContainerStyle}
                            onChangeText={text => {
                                this.setState({
                                    searchText: text.toUpperCase(),
                                    staockDetails: {},
                                    errorMessage: '',
                                })
                            }}
                            clearIcon={null}
                            rightIconContainerStyle={{ color: 'white' }}
                            placeholderTextColor={'#c1c1c1'}
                            value={this.state.searchText}
                            cancelButtonTitle=""
                            autoCapitalize={'characters'}
                            showLoading={this.state.isLoading}
                            onSubmitEditing={() => {
                                this.onSearch()
                            }}

                        />

                        <TouchableOpacity onPress={() => this.onSearch()} style={{
                            width: 120,
                            height: 45,
                            borderRadius: 5,
                            backgroundColor: Colors.APP_COLOR1,
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                            <Text style={[styles.titleText, { color: 'white', fontSize: 14, }]}>Search</Text>
                        </TouchableOpacity>

                    </View>
                    <View style={{ width: '90%', flex: 1 }}>
                        <ScrollView key={'mainContainer'} style={styles.mainContainer}>

                            {this.state.errorMessage != '' &&
                                <View style={{}}>
                                    <Text style={styles.errorMsg} >{this.state.errorMessage}</Text>
                                </View>
                            }

                            {this.state.staockDetails.stock_details != undefined &&

                                <>
                                    <View key={'titleView'} style={{ height: 50, justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row', }}>
                                        <Text key={'titleText'} style={styles.titleText}>{this.state.searchText}</Text>
                                        {parseInt(MyStorage.getItem(Constant.ShareInstanceKey.kPaymentStatus)) == 1 &&
                                            <FavoriteListPopup symbol={this.state.searchText} />
                                        }
                                    </View>

                                    <StockDetailsSection searchedStockDetails={this.state.staockDetails} symbol={this.state.searchText} />
                                </>
                            }
                        </ScrollView>
                    </View>

                </View>

            </View>

        );
    }
}

export default SearchWeb;


const styles = StyleSheet.create({

    headerContainer: {
        flex: 1,
        backgroundColor: Colors.APP_COLOR_BACKGROUND,
    },

    mainContainer: {
        flex: 1,
        backgroundColor: Colors.APP_COLOR_BACKGROUND,
        padding: 30,
        paddingTop: 30,
    },

    titleText: {
        fontSize: 20,
        fontFamily: Constant.AppFonts.Roboto.Bold,
        fontWeight: Constant.AppFontWeight.BOLD,
        color: Colors.MAIN_TITLE_COLOR
    },
    webInputStyle: {
        fontSize: 14,
        fontFamily: Constant.AppFonts.Roboto.Regular,

    },
    webContainerStyle: {
        // width: '100%',
        flex: 1,
        height: 45,
        backgroundColor: 'white',
        borderColor: Colors.APP_COLOR_BORDER,
        borderWidth: 2,
        borderRadius: 5,
        marginRight: 10
    },

    webInputContainerStyle: {
        marginLeft: 0,
        marginRight: 0,
        backgroundColor: 'white',
    },

    errorMsg: {
        color: 'red',
        fontFamily: Constant.AppFonts.Roboto.Regular,
        padding: 5,
        flex: 1,
        textAlign: 'center'
    }

});
