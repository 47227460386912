import * as React from 'react';
import { View, Text, StyleSheet, Image, Platform, TextInput, TouchableOpacity, ImageBackground } from 'react-native'
import Constant from '../../../Utils/Constant';
import { showFlashBanner, } from '../../../Utils/FlashMessage';
import MyStorage from '../../../Services/MyStorage';
import * as Colors from '../../../Utils/Colors';
import Loader from '../../common/LoaderComponent/Loader.web';
import HeaderWeb from '../../../Components/WebComponent/HeaderWeb';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import { Feather } from '@expo/vector-icons';
import { checkValidation } from '../../../Services/AppManager';

import ApiManager from '../../../Services/Networking/ApiManager';
let apiManager = ApiManager.getInstance()


class Login extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            isLoader: false
        }
    }

    onLogin = () => {

        let emailStatus = checkValidation('email', this.state.email, 'Email')
        if (!emailStatus.status) {
            showFlashBanner(emailStatus.messsage, '1')
            return;
        }

        let passwordStatus = checkValidation('text', this.state.password, 'Password')
        if (!passwordStatus.status) {
            showFlashBanner(passwordStatus.messsage, '1')
            return;
        }

        let dict = {
            email: this.state.email,
            password: this.state.password,
        }

        this.setState({ isLoader: true })

        apiManager.onPostApi(Constant.EndPoint.checkSubsription, dict).then((responce) => {
            this.setState({ isLoader: false })
            if (responce.json.status == 200) {
                MyStorage.setItem(Constant.ShareInstanceKey.kUserID, responce.json.data[0].userId)
                MyStorage.setItem(Constant.ShareInstanceKey.kEmailId, responce.json.data[0].email)
                MyStorage.setItem(Constant.ShareInstanceKey.kPaymentStatus, responce.json.data[0].status)
                MyStorage.setItem(Constant.ShareInstanceKey.kAuthToken, responce.json.data[0].token)
                MyStorage.setItem(Constant.ShareInstanceKey.kFetchAllStock, '1')

                // showFlashBanner('You are successfully logged in', '0')
                window.location.href = window.location.origin;

            } else {
                alert(responce.json.message)
            }
        })

    }

    onForgetPassword = () => {
        this.props.navigation.navigate('ForgetPassword', { emailID: this.state.email })
    }


    render() {

        return (

            <View style={{ flex: 1 }}>
                <HeaderWeb props={this.props} pageTitleArray={['Manage Subscription', 'Login']} />

                <ImageBackground key={'mainContainer'}
                    style={styles.mainContainer}
                    source={require('../../../../assets/web/authBg.png')}

                >
                    <View key={'shadowContainer'} style={styles.shadowContainer}>

                        <View style={{ flex: 1, justifyContent: 'center', }}>
                            <View key={'header'} style={{ paddingLeft: 25 }}>
                                <Text style={styles.header}>LOG IN</Text>
                                <Text style={styles.subHeader}>Login to access 5500+ stocks</Text>
                            </View>

                            <View key={'bodyView'} style={{ paddingLeft: 25, paddingRight: 25 }}>
                                <View key={'emailView'} style={styles.emailView}>
                                    <MaterialCommunityIcons name="email-outline" size={21} color="#2996FF" />
                                    <TextInput
                                        type='Email'
                                        name='Email'
                                        placeholder={'Email Address'}
                                        placeholderTextColor="#A5A5A5"
                                        onChangeText={(text) => this.setState({ email: text })}
                                        style={[styles.inputText, { outline: 'none' }]}
                                        value={this.state.email}

                                    />
                                </View>

                                <View key={'passwordView'} style={styles.passwordView}>

                                    <Feather name="lock" size={20} color="#2996FF" />
                                    <TextInput
                                        type='Password'
                                        name='Password'
                                        placeholderTextColor="#A5A5A5"
                                        placeholder={'Password'}
                                        onChangeText={(text) => this.setState({ password: text })}
                                        style={[styles.inputText, { outline: 'none' }]}
                                        secureTextEntry={true}
                                    />
                                </View>

                                <View key={'forgetBtnView'} style={styles.forgetBtnView}>
                                    <View>

                                    </View>
                                    <TouchableOpacity onPress={() => this.onForgetPassword()} key={'forgetBtn'} style={styles.forgetBtn} >
                                        <Text style={styles.forgetText}>Forgot password?</Text>
                                    </TouchableOpacity>
                                </View>

                                <TouchableOpacity
                                    style={styles.buttonView}
                                    onPress={() => this.onLogin()}
                                >
                                    <Text style={styles.buttonText}>LOG IN</Text>
                                </TouchableOpacity>

                            </View>
                        </View>
                        <View style={{ flex: 1, }}>
                            <Image key={'sideImage'}
                                style={{ flex: 1, borderBottomRightRadius: 10, borderTopRightRadius: 10 }}
                                source={require('../../../../assets/web/sideViewImage/NoPath.png')}
                            />
                        </View>
                    </View>
                </ImageBackground>

                {this.state.isLoader && <Loader />}
            </View>
        )

    }
}

export default Login;

const styles = StyleSheet.create({

    mainContainer: {
        flex: 1,
        backgroundColor: Colors.APP_COLOR_BACKGROUND,
        alignItems: 'center',
        justifyContent: 'center'
    },

    shadowContainer: {

        backgroundColor: "white",
        borderRadius: 10,
        shadowColor: 'rgba(0, 0, 0, 0.1)',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 1,
        shadowRadius: 2,
        width: '60%',
        height: '80%',
        flexDirection: 'row'
    },

    header: {
        fontSize: 25,
        color: Colors.MAIN_TITLE_COLOR,
        fontFamily: Constant.AppFonts.Roboto.Bold,
        fontWeight: Constant.AppFontWeight.BOLD,
    },

    subHeader: {
        paddingTop: 15,
        fontSize: 15,
        color: Colors.APP_DARK_GARY,
        fontFamily: Constant.AppFonts.Roboto.Regular,
        fontWeight: Constant.AppFontWeight.NORMAL,
    },

    emailView: {

        marginTop: 25,
        borderWidth: 2,
        borderColor: Colors.APP_COLOR_BORDER,
        borderRadius: 5,
        flexDirection: 'row',
        paddingHorizontal: 10,
        paddingVertical: 10,
        borderColor: 'rgba(0,0,0,0.1)',
        alignItems: 'center'

    },

    inputText: {
        flex: 1,
        paddingHorizontal: 10,
        fontSize: 14,
        paddingVertical: 5,
        fontFamily: Constant.AppFonts.Roboto.Medium,
        fontWeight: Constant.AppFontWeight.MEDIUM,
        color: Colors.MAIN_TITLE_COLOR
    },

    passwordView: {

        marginTop: 20,
        borderWidth: 2,
        borderColor: Colors.APP_COLOR_BORDER,
        borderRadius: 5,
        flexDirection: 'row',
        paddingHorizontal: 10,
        paddingVertical: 10,
        borderColor: 'rgba(0,0,0,0.1)',
        alignItems: 'center'
    },

    buttonView: {
        marginTop: 20,
        backgroundColor: '#007AFF',
        borderRadius: 8,
        paddingVertical: 15,
        paddingHorizontal: 12,
        width: '100%',
    },

    buttonText: {
        fontSize: 16,
        color: '#fff',
        fontWeight: Constant.AppFontWeight.BOLD,
        alignSelf: 'center',
        textTransform: 'uppercase',
    },

    forgetBtn: {
        paddingTop: 10
    },

    forgetText: {

        color: 'rgba(0,0,0,0.3)',
        textDecorationLine: 'underline',
        fontSize: 13,
        fontFamily: Constant.AppFonts.Roboto.Medium,
        fontWeight: Constant.AppFontWeight.MEDIUM,
        // color: Colors.APP_DARK_GARY
    },

    forgetBtnView: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 5,
    }
})