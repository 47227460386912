
import { showMessage } from "react-native-flash-message";

export const showFlashBanner = (message, type ='0') => {

    showMessage({
        message: "Best Stocks Now",
        description: message,
        type: type == '0' ? 'success' : 'danger' ,
        icon:'auto'
      });
}


