
import * as React from 'react';
import { View, Text, FlatList, StyleSheet, Animated, TouchableOpacity, Alert, ActivityIndicator, Image, ScrollView } from 'react-native';
import { connect } from 'react-redux';
import { getStockList, updatePageCount, addStockInFav, updateStockData, addStockData } from '../../Services/Redux/actions/stocksAction';
import { fontSizeScale, getCapBackgroundColor, filterArrayByOptions } from '../../Services/AppManager';
import RBSheet from "react-native-raw-bottom-sheet";
import FavListPopup from './FavListPopup';
import Swipeout from 'react-native-swipeout';
import SimpleLineIcons from 'react-native-vector-icons/SimpleLineIcons'
import * as Colors from '../../Utils/Colors';
import { showFlashBanner } from '../../Utils/FlashMessage';
import FavouriteStocksListModel from '../../Models/FavouriteListModel';
import Constants from '../../Utils/Constant';
import MyStorage from '../../Services/MyStorage';

const ITEM_SIZE = fontSizeScale(110)
const scrollY = new Animated.Value(0)

class StocksListMobile extends React.Component {

    selectedItem = {}
    selectedIndex = 0

    constructor(props) {
        super(props);
        this.state = {
            perPage: 10,
            refreshView: 0,
        }

    }

    onClickToCell = (item) => {

        this.selectedItem = item
        this.selectedIndex = this.props.arrStockData.indexOf(item)
        if (this.selectedItem.favouritesStockList.length > 0) {

            let dict = {
                symkey: this.selectedItem.sym,
                listId: this.selectedItem.favouritesStockList[0].listId,
                userId: MyStorage.getItem(Constants.ShareInstanceKey.kUserID),
                favName: this.selectedItem.favouritesStockList[0].name

            }
            console.log('dict', dict);

            this.confirmationForDelete(dict)

        } else {
            this.RBSheet.open()
        }
    }

    confirmationForDelete = (dict) => {

        Alert.alert(
            Constants.AppText.appTitle,
            "Are you sure to want delete from favourite?",
            [
                {
                    text: "CANCEL",
                    onPress: () => console.log("Cancel Pressed"),
                    style: "cancel"
                },
                { text: "YES", onPress: () => this.updateFavStock(dict, 'delete') }
            ]
        );

    }

    didSelectList = (item) => {
        this.RBSheet.close()

        console.log('item', item);

        let dict = {
            symkey: this.selectedItem.sym,
            listId: item.favListid,
            userId: MyStorage.getItem(Constants.ShareInstanceKey.kUserID),
            favName: item.name

        }

        this.updateFavStock(dict, 'add')

    }


    updateFavStock = (dict, actionType) => {

        let id = Math.floor((Math.random() * 10) + 1);
        var model = new FavouriteStocksListModel()
        model.favListid = id,
            model.listId = dict.listId,
            model.name = dict.symkey

        this.props.addStockInFav(dict).then((res) => {

            if (actionType == 'add') {
                showFlashBanner(`${dict.symkey} successfully added in ${dict.favName}`, '0')
            } else {
                showFlashBanner(`${dict.symkey} removed from list`, '0')

            }

            if (this.props.selectedSubSection.label == 'Hide') {
                let totalStock = this.props.arrStockData
                totalStock.splice(this.selectedIndex, 1)
            } else {
                this.props.updateStockData(model, actionType, this.selectedIndex);
            }

            this.setState({
                refreshView: this.state.refreshView + 1
            })

        });

    }

    onPressCell = (item) => {
        console.log(this.props.props.props);

        this.props.props.props.navigation.navigate('StockDetailsMobile', { symbol: item.sym, sector: item.stkName })
    }

    renderCell = (item, index) => {

        const inputRange = [
            -1,
            0,
            ITEM_SIZE * index,
            ITEM_SIZE * (index + 2),
        ]

        const opecityInputRange = [
            -1,
            0,
            ITEM_SIZE * index,
            ITEM_SIZE * (index + 0.6),
        ]
        const scale = scrollY.interpolate({
            inputRange: inputRange,
            outputRange: [1, 1, 1, 0]
        });

        const newOpecity = scrollY.interpolate({
            inputRange: opecityInputRange,
            outputRange: [1, 1, 1, 0]
        });

        const swipeoutBtns = [
            {
                backgroundColor: item.favouritesStockList.length > 0 ? '#ff0000' : Colors.APP_COLOR1,
                onPress: () => (this.onClickToCell(item, index)),
                component: <SimpleLineIcons name={item.favouritesStockList.length > 0 ? 'minus' : 'plus'} size={30} color={'white'} />
            }
        ]

        const momeontTitle = () => {

            if (this.props.selectedSection == Constants.AppText.StockMarketCap) {
                return "Market Cap"
            }
            if (this.props.selectedSection == Constants.AppText.StockAggressive) {
                return "Risk profile"
            }
            return "Momentum"
        }

        const momeontValue = () => {

            if (this.props.selectedSection == Constants.AppText.StockMarketCap) {
                return item.marketCap
            }
            if (this.props.selectedSection == Constants.AppText.StockAggressive) {
                return item.riskProfile
            }
            return parseFloat(item.momentom).toFixed(2)
        }

        return (



            <Animated.View style={[styles.flatlistContainer]}>

                {/* <Animated.View style={[styles.flatlistContainer, { transform: [{ scale }] }]}> */}
                <View key={index} style={styles.shadowView}>

                    <Swipeout right={swipeoutBtns}
                        backgroundColor="white"
                        style={{ borderRadius: 10 }}
                        autoClose={true}
                        disabled={parseInt(MyStorage.getItem(Constants.ShareInstanceKey.kPaymentStatus)) != 1}

                    >

                        <TouchableOpacity onPress={() => this.onPressCell(item)} style={styles.Container}>
                            <View style={styles.flatlistContainerColumnFirst}>
                                <View style={{}}>
                                    <Text style={styles.title}>{item.sym} ({item.ranking})</Text>
                                    <Text numberOfLines={1} style={styles.subtitle}>{item.stkName}</Text>
                                </View>

                                <View style={styles.grade} >

                                    <Text style={styles.grade_Text} >{(this.props.selectedSection == Constants.AppText.StockMomentum) ? 'Momentum Grade:' : 'Gunderson Grade:'}</Text>
                                    <Text style={styles.grade_Value} >{(this.props.selectedSection == Constants.AppText.StockMomentum) ? item.momentomGrd : item.gundersonGrade}</Text>
                                </View>

                                <View style={styles.grade} >
                                    <Text style={styles.momentum_Text} >{momeontTitle()}:</Text>
                                    <Text style={styles.momentum_Value} >{momeontValue()}</Text>
                                </View>
                            </View>

                            <View style={styles.flatlistContainerColumnSecond}>
                                <View style={[styles.ValueTopCorner, getCapBackgroundColor(item.marketSize)]}>
                                    <Text style={styles.ValueTopCorner_Text}>{item.marketSize}</Text>
                                </View>

                                <View style={styles.closingPriceValue}>
                                    <Text style={styles.lastClosing_Text}>Last Closing Price </Text>
                                    <Text style={{ marginRight: 10, fontSize: fontSizeScale(20), fontFamily: Constants.AppFonts.Roboto.Black, }}>{item.lastClosingPrice}</Text>
                                </View>
                            </View>
                        </TouchableOpacity>
                    </Swipeout>

                </View>
            </Animated.View>

        )
    }

    getRenderData = () => {

        let totalStocks = this.props.arrStockData
        let paginationData = totalStocks.slice(0,
            this.props.page * this.state.perPage + this.state.perPage
        );
        return paginationData
    }

    handleLoadMore = () => {
        if ((this.props.page * this.state.perPage + this.state.perPage) < this.props.arrStockData.length) {
            this.props.updatePageCount(this.props.page + 1)
        }
    }

    getFirstIndex = () => {
        let filterData = filterArrayByOptions(this.props.arrAllStockData, this.props.selectedSubSection)
        return (filterData.map(s => (s.sym.charAt(0)).toUpperCase()).filter((x, i, a) => a.indexOf(x) == i)).sort();
    }

    searchByLetter = (letter) => {

        let selectedLetter = MyStorage.getItem(Constants.ShareInstanceKey.kSelectedAlphabetKey)

        if (selectedLetter == letter) {
            letter = ""
        }

        MyStorage.setItem(Constants.ShareInstanceKey.kSelectedAlphabetKey, letter)

        let filterData = filterArrayByOptions(this.props.arrAllStockData, this.props.selectedSubSection)
        var allRecord = filterData;
        if (letter != '') {
            allRecord = filterData.filter((o) => {
                if (o['sym'] != undefined) {
                    return o['sym'].toLowerCase().startsWith(letter.toLowerCase())
                }
            });
        }

        this.props.addStockData(allRecord)

    }

    renderFooter = () => {

        if ((this.props.page * this.state.perPage + this.state.perPage) < this.props.arrStockData.length) {
            return (
                <View
                    style={{
                        paddingVertical: 20,
                        borderTopWidth: 1,
                        borderColor: "#CED0CE"
                    }}
                >
                    <ActivityIndicator animating size='small' />
                </View>
            );
        }
        return (
            <View
                style={{
                    height: 50
                }}
            />
        );
    };

    renderEmptyData = () => {

        if (this.props.stocksModelData.length > 0 && this.props.selectedSubSection.label != 'Hide') {
            return (
                <View style={{ width: '100%', height: 450, justifyContent: 'center', alignItems: 'center', }}>
                    <Image
                        style={{ width: 100, height: 100 }}
                        source={require('../../../assets/Empty.png')}
                    />
                    <Text style={{ fontSize: 13, fontFamily: Constants.AppFonts.Roboto.Regular, marginTop: 20 }}> No Data to Display for {this.props.selectedSubSection.label} </Text>
                </View>
            )
        }
        return null
    };


    alphabetStyle = (item) => {

        let letter = MyStorage.getItem(Constants.ShareInstanceKey.kSelectedAlphabetKey)
        if (item != letter) {
            return {
                width: 25,
                height: 25,
                marginTop: 2,
                justifyContent: 'center',
                alignItems: 'center'
            }
        }

        return {

            width: 25,
            height: 25,
            marginTop: 2,
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: Colors.APP_COLOR1,
            borderRadius: 12.5,
        }
    }

    render() {

        let letter = MyStorage.getItem(Constants.ShareInstanceKey.kSelectedAlphabetKey)

        let showIndexView = true
        if ((this.props.selectedSubSection.label == 'Hide') || this.props.searchKey != '' || this.getFirstIndex().length < 2) {
            showIndexView = false
        }


        return (

            <View style={{ flex: 1, marginLeft: fontSizeScale(10), marginRight: fontSizeScale(showIndexView ? 5 : 10), }}>

                <View style={{ flex: 1, flexDirection: 'row' }}>

                    <Animated.FlatList
                        style={{ flex: 1, }}
                        extraData={this.props.isMobileRefresh}
                        data={this.getRenderData()}
                        renderItem={({ item, index }) =>
                            this.renderCell(item, index)
                        }
                        showsVerticalScrollIndicator={false}
                        onEndReached={this.handleLoadMore}
                        onEndReachedThreshold={0.3}
                        keyExtractor={item => item.sym}
                        ListFooterComponent={this.renderFooter}
                        ListEmptyComponent={this.renderEmptyData}

                        onScroll={Animated.event(
                            [
                                {
                                    nativeEvent: { contentOffset: { y: scrollY } },
                                },
                            ],
                            {
                                useNativeDriver: true,
                            }
                        )}
                    />
                    {showIndexView &&
                        <View style={{ width: 30, marginTop: 55, alignItems: 'center', justifyContent: 'center', }}>
                            <ScrollView showsVerticalScrollIndicator={false} contentContainerStyle={{ flexGrow: 1, justifyContent: 'center', }}>

                                {
                                    this.getFirstIndex().map((item) => {
                                        return (
                                            <TouchableOpacity key={item} onPress={() => this.searchByLetter(item)} style={this.alphabetStyle(item)}>
                                                <Text style={{ fontSize: fontSizeScale(12), fontFamily: Constants.AppFonts.Roboto.Bold, color: letter == item ? 'white' : 'black' }}>{item}</Text>
                                            </TouchableOpacity>
                                        )
                                    })
                                }
                            </ScrollView>
                        </View>
                    }
                </View>


                <RBSheet
                    ref={ref => {
                        this.RBSheet = ref;
                    }}
                    height={fontSizeScale(320)}
                    openDuration={fontSizeScale(250)}
                    customStyles={{
                        container: {
                            borderTopLeftRadius: 20,
                            borderTopRightRadius: 20

                        }
                    }}
                >
                    <FavListPopup onClickCell={this.didSelectList.bind()} />
                </RBSheet>
            </View>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        arrStockData: state.stocksReducers.stockData,
        stocksModelData: state.stocksReducers.stocksModelData,
        isMobileRefresh: state.stocksReducers.isMobileRefresh,
        page: state.stocksReducers.pageCount,
        favList: state.stockOperationReducers.favList,
        selectedSubSection: state.stocksReducers.selectedSubSection,
        selectedSection: state.stocksReducers.selectedSection,
        arrAllStockData: state.stocksReducers.stocksModelData,
        searchKey: state.stocksReducers.searchKey,

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getStockList: (sec, subSec) => dispatch(getStockList(sec, subSec)),
        updatePageCount: (page) => dispatch(updatePageCount(page)),
        addStockInFav: (request) => dispatch(addStockInFav(request)),
        updateStockData: (favData, actionType, index) => dispatch(updateStockData(favData, actionType, index)),
        addStockData: (searchModel) => dispatch(addStockData(searchModel)),


    }
}


export default connect(mapStateToProps, mapDispatchToProps)(StocksListMobile);

const styles = StyleSheet.create({

    flatlistContainer: {
        flex: 1,
        height: ITEM_SIZE,
        paddingTop: 15,
    },

    shadowView: {
        flex: 1,
        borderRadius: fontSizeScale(10),
        shadowColor: 'rgba(0, 0, 0, 0.1)',
        shadowOffset: { width: 0, height: 3 },
        shadowOpacity: 2,
        shadowRadius: 1,
        elevation: 2,
        width: '100%',
        backgroundColor: 'white'
    },


    Container: {
        flexDirection: "row",
        height: "100%",

    },

    flatlistContainerColumnFirst: {
        flex: 1,
        backgroundColor: "white",
        paddingLeft: fontSizeScale(10),
        paddingTop: fontSizeScale(10),
        paddingBottom: fontSizeScale(10)
    },

    title: {
        fontSize: fontSizeScale(14),
        color: Colors.APP_COLOR1,
        fontFamily: Constants.AppFonts.Roboto.Black
    },

    subtitle: {
        fontSize: fontSizeScale(13),
        color: Colors.APP_DARK_GARY,
        marginTop: fontSizeScale(4),
        fontFamily: Constants.AppFonts.Roboto.Medium
    },

    grade:
    {
        flexDirection: "row",
        flex: 1,
        alignItems: 'center',
        marginTop: fontSizeScale(5),
        // flexWrap: "wrap",
        // backgroundColor:'red',

    },

    grade_Text: {
        color: Colors.APP_DARK_GARY,
        fontSize: fontSizeScale(13),
        fontFamily: Constants.AppFonts.Roboto.Regular
    },

    grade_Value: {
        color: Colors.MAIN_TITLE_COLOR,
        paddingLeft: fontSizeScale(5),
        fontFamily: Constants.AppFonts.Roboto.Bold,
        // paddingTop:1,
        fontSize: fontSizeScale(13),

    },

    momentum_Text: {
        color: Colors.APP_DARK_GARY,
        fontSize: fontSizeScale(13),
        // paddingLeft: fontSizeScale(10),
        fontFamily: Constants.AppFonts.Roboto.Regular
    },

    momentum_Value: {
        color: Colors.MAIN_TITLE_COLOR,
        paddingLeft: fontSizeScale(5),
        fontFamily: Constants.AppFonts.Roboto.Bold,
        // paddingTop:1,
        fontSize: fontSizeScale(13),
    },

    flatlistContainerColumnSecond: {
        alignItems: "flex-end",
        height: '100%',
        borderRadius: 10,
    },

    ValueTopCorner: {

        backgroundColor: Colors.APP_COLOR1,
        borderTopRightRadius: fontSizeScale(10),
        height: fontSizeScale(25),
        paddingLeft: fontSizeScale(10),
        paddingRight: fontSizeScale(10),
        minWidth: fontSizeScale(70),
        justifyContent: 'center',
        alignItems: 'center',
    },

    ValueTopCorner_Text: {
        color: "white",
        fontFamily: Constants.AppFonts.Roboto.Regular,
        fontSize: fontSizeScale(12)
    },

    closingPriceValue: {
        paddingTop: fontSizeScale(15),
        justifyContent: "flex-end",
        alignItems: "flex-end"
    },

    lastClosing_Text: {
        color: "black",
        fontSize: fontSizeScale(13),
        alignItems: "flex-end",
        marginRight: 10,
        fontFamily: Constants.AppFonts.Roboto.Regular
    },
});
