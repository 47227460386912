import { Dimensions, Platform } from "react-native";
import { scale } from 'react-native-size-matters';


export default {

  API: {
    BASE_URL: 'https://projects2.sofmen.com/beststocks-laravel/api/',
    WEB_BASE_URL: 'https://blooming-lake-03275.herokuapp.com/https://projects2.sofmen.com/beststocks-laravel/api/',
    ABOUT_GUNDERSON: 'https://www.gundersoncapital.com/bill-gunderson/',
    RADIO_SHOW: 'https://www.gundersoncapital.com/radio-show-archives/',
    UPCOMING_WEBNIAR: 'https://www.gundersoncapital.com/blog/',
    // TWITTER_SHOW: 'https://stocktwits.com/billgunderson'
    TWITTER_SHOW: 'https://projects2.sofmen.com/beststocks-laravel/twitter',
    PRIVACY_POLICY: 'http://gundersoncapital.com/privacy-policy/',
    T_C: 'http://gundersoncapital.com/terms-of-use/'


  },

  EndPoint: {

    getAllStocks: 'getAllStocks',
    getStockDetail: 'stockDetail',
    getFavList: 'getFavList',
    createFavList: 'createFavList',
    addStockInFav: 'updateFavoriteStockList',
    getStockInList: 'getStockInList',
    changePassword: 'auth/changePassword',
    registerEmail: 'auth/registerEmail',
    verifyPaymentIOS: 'auth/verifyPayment',
    verifyWebPayment: 'auth/verifyWebPayment',
    verifyPaymentAndroid: 'auth/verifyAndroidPayment',
    verifyWebPayment: 'auth/verifyWebPayment',
    checkSubsription: 'auth/checkSubsription',
    forgotPassword: 'auth/forgotPassword',
    getSubsription: 'auth/getSubsription',
    changeEmail: 'auth/changeregisterEmail',
    checkedEmail: 'auth/checkedEmail',
    logOut: 'auth/logout',




  },

  SubscriptionPlan: {

    // NORMAL: 'BSNPremium1MAutoRenewBeta',
    // PREMIUM: 'BSN_PREMIUM_AUTORENEW_BETA',

    NORMAL: 'BSNPremium1MAutoRenew',
    PREMIUM: 'BSN_PREMIUM_AUTORENEW',

    NORMAL_ANDROID: 'bsn_monthly_sub',
    PREMIUM_ANDROID: 'bsn_premium_autorenew',


    // BackUp
    NORMAL_PROD: 'BSNPremium1MAutoRenew',
    PREMIUM_PROD: 'BSN_PREMIUM_AUTORENEW',

    NORMAL_BETA: 'BSNPremium1MAutoRenewBeta',
    PREMIUM_BETA: 'BSN_PREMIUM_AUTORENEW_BETA',


  },

  SubscriptionPrice: {
    NORMAL: '9.99',
    PREMIUM: '89.99'
  },

  AppFonts: {

    Roboto: {

      Light: Platform.OS != 'web' ? 'Roboto-Light' : require('../../../assets/fonts/Roboto-Bold.ttf'),
      Thin: Platform.OS != 'web' ? 'Roboto-Thin' : require('../../../assets/fonts/Roboto-Thin.ttf'),
      Regular: Platform.OS != 'web' ? 'Roboto-Regular' : require('../../../assets/fonts/Roboto-Regular.ttf'),
      Medium: Platform.OS != 'web' ? 'Roboto-Medium' : require('../../../assets/fonts/Roboto-Medium.ttf'),
      Bold: Platform.OS != 'web' ? 'Roboto-Bold' : require('../../../assets/fonts/Roboto-Bold.ttf'),
      Black: Platform.OS != 'web' ? 'Roboto-Black' : require('../../../assets/fonts/Roboto-Black.ttf'),

      LightItalic: Platform.OS != 'web' ? 'Roboto-LightItalic' : require('../../../assets/fonts/Roboto-LightItalic.ttf'),
      ThinItalic: Platform.OS != 'web' ? 'Roboto-ThinItalic' : require('../../../assets/fonts/Roboto-ThinItalic.ttf'),
      MediumItalic: Platform.OS != 'web' ? 'Roboto-MediumItalic' : require('../../../assets/fonts/Roboto-MediumItalic.ttf'),
      BoldItalic: Platform.OS != 'web' ? 'Roboto-BoldItalic' : require('../../../assets/fonts/Roboto-BoldItalic.ttf'),
      BlackItalic: Platform.OS != 'web' ? 'Roboto-BlackItalic' : require('../../../assets/fonts/Roboto-BlackItalic.ttf'),
      Italic: Platform.OS != 'web' ? 'Roboto-Italic' : require('../../../assets/fonts/Roboto-Italic.ttf'),
    }
  },

  AppFontWeight: {

    THIN: '100',
    EXTRA_LIGHT: '200',
    LIGHT: '300',
    NORMAL: '400',
    MEDIUM: '500',
    SEMI_BOLD: '600',
    BOLD: '700',
    EXTRA_BOLD: '800',
    BLACK: '900'
  },

  AppStringMessages: {
    msgNoInternet: "Please check your network connection and try again.",
    logoutConfirmationMessage: "Are you sure you want to logout?",
    deleteAccountConfirmationMessage: "Are you sure you want to delete your account?",
    TermsAndConditionMessage: "Please accept the terms and conditions and privacy policy."

  },

  AppText: {
    appTitle: 'Best Stocks Now',

    Search: 'Search',
    AllStocks: 'All Stocks',
    StockMomentum: 'Complete Momentum List',
    StockBetterB: 'Complete B+ or Better List',
    StockRanking: 'All Rankings List',
    StockMarketCap: 'Ranking by Market Cap',
    StockAggressive: 'Ranking by Risk Profile',
    StockMutualRanking: 'Mutual Fund Ranking',
    StockETFRanking: 'ETF Ranking',

    SideMenu_Title_StockRankings: "Today's Stock Rankings",
    SideMenu_Title_MyLists: "My Lists",
    SideMenu_Title_AboutBestStocksNow: "About Best Stocks Now",
    SideMenu_Title_BSNShowsAndMore: "BSN Shows and More...",
    SideMenu_Title_AccountManagement: "Account Management",


    SubscriptionAlert_1: 'Subscriptions will last for the duration specified, and renew within 24-hours prior to the end of the current period for the selected price, unless auto-renew is turned on at least 24-hours before the end of the current period.',
    SubscriptionAlert_2: "Subscriptions may be managed by the user and auto-renewal may be turned off by going to the user's Account Settings after purchase.",
    SubscriptionAlert_3: 'Any unused portion of a free trial period, if offered, will be forfeited when the user purchases a subscription to that publication, where applicable',

    SubscriptionBox_1_WEB: 'This subscription allows unlimited access to all 5500+ stocks I review. Tap the buttons below to purchase a subscription for the price specified. Payment will be charged to paypal Account at confirmation of purchase.',
    SubscriptionBox_1: 'This subscription allows unlimited access to all 5500+ stocks I review. Tap the buttons below to purchase a subscription for the price specified.',
    SubscriptionBox_2: 'Get all the benefits of Monthly Subscription plan \n\nGet Push Notifications of Bill Gunderson’s live Trades.',
    Registration: 'Please register your purchase, In order to access premium subscription benefit on your other device. \n\nYou will get the password on your email for future reference, you can always change the password from Subscription Manager screen.',
    ForgetPassword: 'We will send the password on your given email, you can always change the password from Subscription Manager screen.',
    inAppPurchaseGuild: "Subscriptions will last for the duration specified, and renew within 24-hours prior to the end of the current period for the selected price, unless auto-renew is turned off at least 24-hours before the end of the current period. \n\nSubscriptions may be managed by the user and auto-renewal may be turned off by going to the user's Account Settings after purchase. \n\nAny unused portion of a free trial period, if offered, will be forfeited when the user purchases a subscription to that publication, where applicable",

    paypalLoginInfoText: "Password will send to your provided email for next time login,\
                          To access the premium subscription benefit on your other device,\
                          You can always change your email from the “Subscription Manager” section.",


    stockDetailWebInfo: "Best stocks now app provides general information and is not an offer to buy or sell any security. It is the sole opinion of the creator, bill gunderson. Bill gunderson is president of gunderson capital management INC. A registered investment advisory firm located at 2072 willbrook lane MT. Pleasant, SC 29466 phone no. (855)611-best. Information in best stocks now app comes from independent sources believed to be reliable, but accuracy is not guaranteed. Bill gunderson or gunderson capital mgt. INC. Has not verified the accuracy of the information published by the independent sources. Selections in best stocks now app are the sole opinions of bill gunderson and are subject to risks and uncertainties beyond the control of bill gunderson and gunderson capital mgt. INC. Such risks and uncertainties include national, international, economic conditions and fluctuations; Economic conditions of specific sectors and groups and companies; Management capabilities of companies selected. Selections in best stocks now app may not yield profitable results and may actually result in a loss of investment capital. As an investment advisors, bill gunderson, or clients of gunderson capital mgt. INC. May purchase and sell securities identified either in their own accounts or accounts that they manage. They may also buy or sell securities mentioned without notice to app subscribers. If you have any questons, you can contact bill gunderson at (855)611-best. 2021-01-02 (Dow view)",

    changeEmail: "You will get the email verification on your new email id, Your email will be changed with us once you verify your email.",

    errorMsg200: 'This Symbol is not tracked by Gunderson Capital Management',
    errorMsg201: 'This Symbol is not available in the Demo version'




  },

  ShareInstanceKey: {

    kUserID: 'kUserID',
    kEmailId: 'kEmailId',
    kSubscriptionEndDate: 'kSubscriptionEndDate',
    kPaymentStatus: 'kPaymentStatus',
    kAuthToken: 'kAuthToken',
    kFetchAllStock: 'kFetchAllStock',
    kLastSyncAllStocks: 'kLastSyncAllStocks',
    kSelectedAlphabetKey: 'kSelectedAlphabetKey',
    kDeviceToken: 'kDeviceToken',
    kISFirstTypePayment: 'kISFirstTypePayment',


  },

  ErrorMessage: {

    LoginPasswordMessage: "Unable to login email password is not correct",
    PasswordError: 'Password should contains at least six numbers, letters, and punctuation marks',
    UserNameError: 'Username should be between 5 - 25 characters',
    ValidEmailError: 'Please enter valid email address',
    FirstNameError: 'First name should be between 2 - 20 characters',
    LastNameError: 'Last name should be between 2 - 20 characters',
    ServerError: 'Something went wrong, Please try again',

  },


  blankSubSection: {
    'label': 'Hide'
  },


  arrGrade: [

    { 'label': "All", 'Name': "All", 'value': 'All', 'key': 'gundersonGrade', },

    { 'label': "Gunderson Grade A", 'Name': "Gunderson Grade A", 'value': 'Gunderson Grade A', 'key': 'gundersonGrade', 'data': 'A' },
    { 'label': "Gunderson Grade A+", 'Name': "Gunderson Grade A+", 'value': 'Gunderson Grade A+', 'key': 'gundersonGrade', 'data': 'A+' },
    { 'label': "Gunderson Grade A-", 'Name': "Gunderson Grade A-", 'value': 'Gunderson Grade A-', 'key': 'gundersonGrade', 'data': 'A-' },

    { 'label': "Gunderson Grade B", 'Name': "Gunderson Grade B", 'value': 'Gunderson Grade B', 'key': 'gundersonGrade', 'data': 'B' },
    { 'label': "Gunderson Grade B+", 'Name': "Gunderson Grade B+", 'value': 'Gunderson Grade B+', 'key': 'gundersonGrade', 'data': 'B+' },
    { 'label': "Gunderson Grade B-", 'Name': "Gunderson Grade B-", 'value': 'Gunderson Grade B-', 'key': 'gundersonGrade', 'data': 'B-' },

    { 'label': "Gunderson Grade C", 'Name': "Gunderson Grade C", 'value': 'Gunderson Grade C', 'key': 'gundersonGrade', 'data': 'C' },
    { 'label': "Gunderson Grade C+", 'Name': "Gunderson Grade C+", 'value': 'Gunderson Grade C+', 'key': 'gundersonGrade', 'data': 'C+' },
    { 'label': "Gunderson Grade C-", 'Name': "Gunderson Grade C-", 'value': 'Gunderson Grade C-', 'key': 'gundersonGrade', 'data': 'C-' },

    { 'label': "Gunderson Grade D", 'Name': "Gunderson Grade D", 'value': 'Gunderson Grade D', 'key': 'gundersonGrade', 'data': 'D' },
    { 'label': "Gunderson Grade D+", 'Name': "Gunderson Grade D+", 'value': 'Gunderson Grade D+', 'key': 'gundersonGrade', 'data': 'D+' },
    { 'label': "Gunderson Grade D-", 'Name': "Gunderson Grade D-", 'value': 'Gunderson Grade D-', 'key': 'gundersonGrade', 'data': 'D-' },

    { 'label': "Gunderson Grade F", 'Name': "Gunderson Grade F", 'value': 'Gunderson Grade F', 'key': 'gundersonGrade', 'data': 'F' },
    { 'label': "Gunderson Grade F+", 'Name': "Gunderson Grade F+", 'value': 'Gunderson Grade F+', 'key': 'gundersonGrade', 'data': 'F+' },

    { 'label': "Gunderson Grade NA", 'Name': "Gunderson Grade NA", 'value': 'Gunderson Grade NA', 'key': 'lastClosingPrice', 'data': '0' },
  ],


  betterFromB: [

    { 'label': "All", 'Name': "All", 'value': 'All', 'key': 'gundersonGrade' },

    { 'label': "Gunderson Grade A+", 'Name': "Gunderson Grade A+", 'value': 'Gunderson Grade A+', 'key': 'gundersonGrade', 'data': 'A+' },
    { 'label': "Gunderson Grade A", 'Name': "Gunderson Grade A", 'value': 'Gunderson Grade A', 'key': 'gundersonGrade', 'data': 'A' },
    { 'label': "Gunderson Grade A-", 'Name': "Gunderson Grade A-", 'value': 'Gunderson Grade A-', 'key': 'gundersonGrade', 'data': 'A-' },

    { 'label': "Gunderson Grade B+", 'Name': "Gunderson Grade B+", 'value': 'Gunderson Grade B+', 'key': 'gundersonGrade', 'data': 'B+' },

  ],


  arrRiskProfile: [

    { 'label': "All", 'Name': "All", 'value': 'All', 'key': 'riskProfile' },

    { 'label': "Aggressive", 'Name': "Aggressive", 'value': 'Aggressive', 'key': 'riskProfile' },
    { 'label': "Conservative", 'Name': "Conservative", 'value': 'Conservative', 'key': 'riskProfile' },
    { 'label': "Growth & Income", 'Name': "Growth & Income", 'value': 'Growth & Income', 'key': 'riskProfile' },
    { 'label': "Income", 'Name': "Income", 'value': 'Income', 'key': 'riskProfile' },
    { 'label': "Moderate", 'Name': "Moderate", 'value': 'Moderate', 'key': 'riskProfile' },
    { 'label': "Speculative", 'Name': "Speculative", 'value': 'Speculative', 'key': 'riskProfile' },

  ],


  arrCap: [

    { 'label': "All", 'Name': "All", 'value': '', 'key': 'marketSize' },

    { 'label': "Micro-Cap", 'Name': "Micro-Cap", 'value': 'Micro-Cap', 'key': 'marketSize' },
    { 'label': "Small-Cap", 'Name': "Small-Cap", 'value': 'Small-Cap', 'key': 'marketSize' },
    { 'label': "Mid-Cap", 'Name': "Mid-Cap", 'value': 'Mid-Cap', 'key': 'marketSize' },
    { 'label': "Large-Cap", 'Name': "Large-Cap", 'value': 'Large-Cap', 'key': 'marketSize' },
    { 'label': "Mega-Cap", 'Name': "Mega-Cap", 'value': 'Mega-Cap', 'key': 'marketSize' },

  ],

  arrCompanyName: [

    { 'label': "All", 'Name': "All", 'value': 'All', 'key': 'stkName', },

    { 'label': "Company Name: A-C", 'Name': "Company Name: A-C", 'value': 'Company Name: A-C', 'key': 'stkName', 'data': 'A,B,C' },
    { 'label': "Company Name: D-F", 'Name': "Company Name: D-F", 'value': 'Company Name: D-F', 'key': 'stkName', 'data': 'D,E,F' },
    { 'label': "Company Name: G-J", 'Name': "Company Name: G-J", 'value': 'Company Name: G-J', 'key': 'stkName', 'data': 'G,H,I,J' },
    { 'label': "Company Name: K-N", 'Name': "Company Name: K-N", 'value': 'Company Name: K-N', 'key': 'stkName', 'data': 'K,L,M,N' },
    { 'label': "Company Name: O-S", 'Name': "Company Name: O-S", 'value': 'Company Name: O-S', 'key': 'stkName', 'data': 'O,P,Q,R,S' },
    { 'label': "Company Name: T-Z", 'Name': "Company Name: T-Z", 'value': 'Company Name: T-Z', 'key': 'stkName', 'data': 'T,U,V,W,X,Y,Z' },

  ],

  arrRank: [

    { 'index': 0, 'label': "All", 'Name': "All", 'value': 'All', 'key': 'ranking', 'data': '1, 0' },

    { 'index': 0, 'label': "Rank 1-300", 'Name': "Rank 1-300", 'value': 'Rank 1-300', 'key': 'ranking', 'data': '1, 300' },
    { 'index': 1, 'label': "Rank 301-600", 'Name': "Rank 301-600", 'value': 'Rank 301-600', 'key': 'ranking', 'data': '301, 600' },
    { 'index': 2, 'label': "Rank 601-900", 'Name': "Rank 601-900", 'value': 'Rank 601-900', 'key': 'ranking', 'data': '601, 900' },
    { 'index': 3, 'label': "Rank 901-1200", 'Name': "Rank 901-1200", 'value': 'Rank 901-1200', 'key': 'ranking', 'data': '901, 1200' },
    { 'index': 4, 'label': "Rank 1201-1500", 'Name': "Rank 1201-1500", 'value': 'Rank 1201-1500', 'key': 'ranking', 'data': '1201, 1500' },
    { 'index': 5, 'label': "Rank 1501-1800", 'Name': "Rank 1501-1800", 'value': 'Rank 1501-1800', 'key': 'ranking', 'data': '1501, 1800' },
    { 'index': 6, 'label': "Rank 1801-2100", 'Name': "Rank 1801-2100", 'value': 'Rank 1801-2100', 'key': 'ranking', 'data': '1801, 2100' },
    { 'index': 7, 'label': "Rank 2101-2400", 'Name': "Rank 2101-2400", 'value': 'Rank 2101-2400', 'key': 'ranking', 'data': '2101, 2400' },
    { 'index': 8, 'label': "Rank 2401-2700", 'Name': "Rank 2401-2700", 'value': 'Rank 2401-2700', 'key': 'ranking', 'data': '2401, 2700' },
    { 'index': 9, 'label': "Rank 2701-3000", 'Name': "Rank 2701-3000", 'value': 'Rank 2701-3000', 'key': 'ranking', 'data': '2701, 3000' },
    { 'index': 10, 'label': "Rank 3001-3300", 'Name': "Rank 3001-3300", 'value': 'Rank 3001-3300', 'key': 'ranking', 'data': '3001, 3300' },
    { 'index': 11, 'label': "Rank 3301-3600", 'Name': "Rank 3301-3600", 'value': 'Rank 3301-3600', 'key': 'ranking', 'data': '3301, 3600' },
    { 'index': 12, 'label': "Rank 3600+", 'Name': "Rank 3600+", 'value': 'Rank 3600+', 'key': 'ranking', 'data': '3601' },

  ],

  arrMatualFund: [
    { 'index': 0, 'label': "Mutual Fund", 'Name': "Mutual Fund", 'value': 'Mutual Fund', 'key': 'sector' },
  ],

  arrETF: [
    { 'index': 0, 'label': "ETF", 'Name': "ETF", 'value': 'ETF', 'key': 'etf', 'data': "1" },
  ],

};

