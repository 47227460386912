import React from 'react';
import { View, Text, TouchableOpacity, FlatList, StyleSheet, TextInput, ActivityIndicator } from 'react-native';
import { ListItem } from 'react-native-elements';
import { fontSizeScale } from '../../Services/AppManager';
import { connect } from 'react-redux';
import { createFavList } from '../../Services/Redux/actions/stocksAction';
import Octicons from 'react-native-vector-icons/Octicons'
import * as Colors from '../../Utils/Colors';
import MyStorage from '../../Services/MyStorage';
import Constant from '../../Utils/Constant';
import { Ionicons } from '@expo/vector-icons';
import Prompt from '../common/PopupWithInput';


class FavListPopup extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            createListName: '',
            visible: false,
            isLoader: false
        }
    }

    didClickAddNewList = () => {
        this.setState({
            visible: true
        });
    }

    handlePopupCancel = () => {
        this.setState({
            visible: false,
            createListName: ''
        })
    }

    handlePopupCreate = () => {

        if (this.state.createListName != '') {
            let dict = {
                listName: this.state.createListName,
                userId: MyStorage.getItem(Constant.ShareInstanceKey.kUserID)
            }
            this.setState({
                visible: false,
                createListName: '',
                isLoader: true
            })

            this.props.createFavList(dict).then((abc) => {
                console.log('Created');
                this.setState({
                    isLoader: false
                });
            });
        }
    }


    renderEmptyContainer = () => {

        return (
            <View style={{ width: '100%', height: 200, justifyContent: 'center', alignItems: 'center' }}>
                <Text style={{ color: 'gray' }}>You have not create any list yet. </Text>
            </View>
        )
    }

    render() {

        return (

            <View key={'Main'} style={styles.bottomNavigationView}>
                <View style={{ flex: 1, marginTop: fontSizeScale(20), marginLeft: fontSizeScale(15), marginRight: fontSizeScale(15) }}>
                    <View style={{ justifyContent: 'space-between', flexDirection: 'row' }}>
                        <Text style={{ fontSize: fontSizeScale(16), color: '#2996FF' }}>Choose One</Text>
                        {!this.state.isLoader &&
                            <TouchableOpacity onPress={() => this.didClickAddNewList()} style={{ width: 60, height: 30, justifyContent: 'center', alignItems: 'flex-end' }}>
                                <Octicons size={20} color={Colors.APP_COLOR1} name={'plus'} />
                            </TouchableOpacity>
                        }
                        {this.state.isLoader &&
                            <ActivityIndicator size='small' color={Colors.APP_COLOR1} />
                        }
                    </View>
                    <View style={{ marginTop: fontSizeScale(10), height: fontSizeScale(1), backgroundColor: '#DADADA' }} />
                    <View style={{ flex: 1 }}>
                        <FlatList
                            data={this.props.favList}
                            showsVerticalScrollIndicator={false}
                            renderItem={({ item, index }) => (

                                <ListItem key={'index_' + index} onPress={() => this.props.onClickCell(item)} bottomDivider key={item.id} containerStyle={{}} >
                                    <Ionicons name="list" size={24} color={Colors.APP_DARK_GARY} />
                                    <ListItem.Content containerStyle={{}}>
                                        <ListItem.Title>{item.name}</ListItem.Title>
                                    </ListItem.Content>
                                    <ListItem.Chevron />
                                </ListItem>
                            )}
                            keyExtractor={item => item.id}
                            ListEmptyComponent={this.renderEmptyContainer()}

                        />
                    </View>
                </View>


                <Prompt

                    promptBoxStyle={{
                        padding: 0,
                        backgroundColor: "#e8e8e8",
                        borderRadius: 10
                    }}
                    title="Enter List Name"
                    headingStyle={{
                        marginTop: 20,
                        color: Colors.MAIN_TITLE_COLOR,
                        fontFamily: Constant.AppFonts.Roboto.Bold,
                        fontSize: fontSizeScale(18),
                    }}
                    placeholder="My List"
                    isVisible={this.state.visible}
                    inputStyle={styles.inputText}
                    autoFocus={true}
                    onChangeText={(text) => {
                        this.setState({ createListName: text });
                    }}

                    btnTextStyle={{
                        fontFamily: Constant.AppFonts.Roboto.Medium,
                        fontSize: fontSizeScale(14)
                    }}

                    cancelButtonText={'Cancel'}
                    onCancel={() => this.handlePopupCancel()}

                    submitButtonText={'Create'}
                    onSubmit={() => this.handlePopupCreate()}
                />
                
            </View>

        );
    }
}

const mapStateToProps = (state) => {
    return {
        favList: state.stockOperationReducers.favList
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        createFavList: (request) => dispatch(createFavList(request)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FavListPopup);


const styles = StyleSheet.create({

    bottomNavigationView: {
        backgroundColor: '#fff',
        height: fontSizeScale(300),
    },

    inputView: {

        borderRadius: fontSizeScale(8),
        height: fontSizeScale(40),
        alignItems: 'center',
        borderWidth: 1,
        borderColor: '#C0C0C0'
    },

    inputText: {
        width: '90%',
        fontSize: fontSizeScale(14),
        height: fontSizeScale(40),
        paddingStart: fontSizeScale(10),
        fontFamily: Constant.AppFonts.Roboto.Medium,
        color: Colors.MAIN_TITLE_COLOR,
        backgroundColor: '#D3D3D3',
        borderRadius: fontSizeScale(8),
        borderWidth: 1,
        borderColor: '#C0C0C0',
        borderBottomWidth: 1
    },



});