
import * as React from 'react';
import { View, Text, Button } from 'react-native';
import SearchWeb from '../../Components/WebComponent/SearchWeb';
import FilterList from '../../Components/Mobile/FilterList';
import { Card } from 'react-native-elements';
import FilterDropDownWeb from '../WebComponent/FIlterOption.web'
import Constant from '../../Utils/Constant';

class FilterDropDown extends React.Component {

    onDeleteItem = () => {
    }
    render() {
        return (

            <View style={{ flex: 1, backgroundColor: 'white', height: 50, flexDirection: 'row', justifyContent: 'space-between', }}>
                <SearchWeb />

                <View style={{ height: 45, justifyContent: 'center', alignItems: 'center' }}>
                    {(this.props.selectedSection != Constant.AppText.StockETFRanking && this.props.selectedSection != Constant.AppText.StockMutualRanking) &&
                        <FilterDropDownWeb />
                    }
                </View>
            </View>

        );
    }
}


export default FilterDropDown;