
export const DASHBOARD_DATA = 'DASHBOARD_DATA';
export const STOCK_DATA = 'STOCK_DATA';
export const SECTION = 'SECTION';
export const SUB_SECTION = 'SUB_SECTION';
export const API_LOADER = 'API_LOADER';
export const SEARCH_KEY = 'SEARCH_KEY';
export const STOCKS_DATA = 'STOCKS_DATA';
export const PAGE_COUNT = 'PAGE_COUNT';
export const STORE_FAVOURITE = 'STORE_FAVOURITE';
export const ADD_FAVOURITE = 'ADD_FAVOURITE';
export const ADD_FAV_STOCKS = 'ADD_FAV_STOCKS';
export const UPDATE_STOCK_DATA = 'UPDATE_STOCK_DATA';
export const UPDATE_STOCK_MODEL_DATA = 'UPDATE_STOCK_MODEL_DATA';

export const DRAWER_TOGGLE = 'DRAWER_TOGGLE';
export const OPEN_DRAWER_TOGGLE = 'OPEN_DRAWER_TOGGLE';

export const FAVOURITE_HISTORY = 'FAVOURITE_HISTORY';
export const SUBSCRIPTION_HISTORY = 'SUBSCRIPTION_HISTORY';
export const CLEAR_ALL_DATA = 'CLEAR_ALL_DATA';
export const CRON_JOB_TIME = 'CRON_JOB_TIME';
export const SELECTED_SMALL_SIDE_MENU = 'SELECTED_SMALL_SIDE_MENU';



















