import * as React from 'react';
import { View, Text, StyleSheet, TouchableOpacity, FlatList } from 'react-native';
import { List, DataTable, ActivityIndicator } from 'react-native-paper';
import Constant from '../../../Utils/Constant';
import * as Colors from '../../../Utils/Colors';
import StockDetailKeys from './StockDetailKeys';
import ApiManager from '../../../Services/Networking/ApiManager';
import { getSingleRowValue, getRowValueWithNumber } from './StockDetailsHelper';
import MyStorage from '../../../Services/MyStorage';
import Loader from '../../common/LoaderComponent/Loader';
import { connect } from 'react-redux';
import { favStockDetails } from '../../../Services/Redux/actions/stocksAction';
import FavouriteStocksListModel from '../../../Models/FavouriteStocksListModel'
import { showFlashBanner } from '../../../Utils/FlashMessage';
import parse from 'html-react-parser';
import { isHtml } from '../../../Services/AppManager';

let apiManager = ApiManager.getInstance()

var CONTENT = StockDetailKeys.SECTION_WEBSITE_KEYS

class StockDetailsSection extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            refesh: 0,
            stockDetailObj: {},
            isLoading: false

        }
    }

    componentDidMount() {

        if (this.props.searchedStockDetails != undefined) {
            this.parserData(this.props.searchedStockDetails)

        } else {
            this.getStockDetails()
        }


    }


    getStockDetails = () => {

        this.setState({ isLoading: true })
        var dict = {
            'symKey': this.props.symbol,
        }
        let userID = MyStorage.getItem(Constant.ShareInstanceKey.kUserID)
        if (userID != '') {
            dict['userId'] = userID
        }
        apiManager.onGetApi(Constant.EndPoint.getStockDetail, dict).then((responce) => {
            console.log(responce);
            this.setState({ isLoading: false })
            this.parserData(responce.json.data)

            // var favouritesStockList = []
            // if (responce.json.data.stock_details.length > 0) {
            //     let stock = responce.json.data.stock_details[0]
            //     if (stock.favourites_stock_list != undefined) {
            //         stock.favourites_stock_list.forEach(favlList => {
            //             let model = new FavouriteStocksListModel()
            //             favouritesStockList.push(model.init(favlList))
            //         });
            //     }

            //     this.props.favStockDetails(favouritesStockList)
            //     this.updateLocalKey(responce.json.data)
            // }

        }).catch((error) => {
            console.log(error.msg);
            this.setState({ isLoading: false })
            showFlashBanner(error.msg, '1')
            // this.props.navigation.goBack()
        })
    }

    parserData = (responceData) => {

        var favouritesStockList = []
        if (responceData.stock_details.length > 0) {
            let stock = responceData.stock_details[0]
            if (stock.favourites_stock_list != undefined) {
                stock.favourites_stock_list.forEach(favlList => {
                    let model = new FavouriteStocksListModel()
                    favouritesStockList.push(model.init(favlList))
                });
            }

            this.props.favStockDetails(favouritesStockList)
            this.updateLocalKey(responceData)
        }

    }

    updateLocalKey = (data) => {

        CONTENT[5].categoryName = `Performance of ${data.stock_details[0].sym} vs S&P 500`;

        var subCategory = CONTENT[5].subCategory

        const currentYear = new Date().getFullYear();

        var newSubCategory = []
        subCategory.map((item, index) => {

            var tempItem = item
            const previousYear = currentYear - index;
            let _rowTitle = currentYear == previousYear ? `${previousYear} YTD Return` : `${previousYear} Total Return`
            let _rowValueKey = `last_ten_year_performance_key`
            let _getSecValueKey = `cls_prc_${previousYear}1231`
            let _get1stValueKey = `ret_${previousYear}`

            let _prefixString = ''
            let _suffixString = '%'
            let dict = {
                id: index + 10,
                rowTitle: _rowTitle,
                rowValueKey: _rowValueKey,
                prefixString: _prefixString,
                suffixString: _suffixString,
                getSecValueKey: _getSecValueKey,
                get1stValueKey: _get1stValueKey

            }
            tempItem.push(dict)
            newSubCategory.push(tempItem)
        })

        CONTENT[5].subCategory = newSubCategory;

        var stockComments = []
        data.stock_nts.map((comment, index) => {
            let dict = {
                id: index,
                rowTitle: comment.nts,
                rowValueKey: 'comment',
                date: comment.fmt_nts_dt
            }
            stockComments.push(dict)
        });

        if (stockComments.length == 0) {
            let dict = {
                id: 1,
                rowTitle: 'There are no comments.',
                rowValueKey: 'comment',
                date: ''
            }
            stockComments.push(dict)
        }

        CONTENT[6].subCategory = stockComments
        this.setState({
            stockDetailObj: data
        });
    }

    getValue = (item) => {

        const { stockDetailObj } = this.state
        if (item.rowValueKey == 'comment') {
            let isHtmlStr = isHtml(item.rowTitle)
            if (isHtmlStr) {
                let dateHtml = `<html> <style>
                body {background-color: white; color: #515253; font-family: Roboto, Helvetica, sans-serif; font-size: 13px;}
                p    {color: #515253; font-family: Roboto, Helvetica, sans-serif; font-size: 13px;}                
                </style>
                 ${item.date}&nbsp; ${item.rowTitle} 
                 </html>`

                return parse(dateHtml)
            }
            return (
                <Text style={styles.commentText}>{(item.date + ' ' + item.rowTitle).trim()}</Text>
            )
        }
        let value = getSingleRowValue(item, stockDetailObj)
        if (value == 'N/A') return value
        if (value == null) return 'N/A'
        if (!isNaN(value)) return item.prefixString + value.toLocaleString('en-US') + item.suffixString
        return item.prefixString + value + item.suffixString
    }

    getValueForMultipleRows = (item, rowNumber) => {

        const { stockDetailObj } = this.state
        let value = getRowValueWithNumber(item, stockDetailObj, rowNumber)
        if (value == 'N/A') return value
        if (value == null) return 'N/A'
        if (!isNaN(value)) return item.prefixString + value.toLocaleString('en-US',) + item.suffixString
        return item.prefixString + value + item.suffixString
    }

    renderBody = (item, index) => {

        let jsx = item.subCategory.map((subItem, idx) => {

            if (item.numberOfColumns == 1) {
                return (
                    <DataTable.Row key={idx} style={{ backgroundColor: 'white', borderBottomWidth: 0, paddingLeft: 15, paddingRight: 15, }} >
                        <View style={{ flex: 1, justifyContent: 'center', }}>
                            <Text style={styles.textTitleValueStyle}>{this.getValue(subItem)}</Text>
                        </View>
                    </DataTable.Row>
                )
            }

            if (item.numberOfColumns == 11) {
                // Comment Section
                return (
                    <DataTable.Row key={idx} style={{ backgroundColor: 'white', borderBottomColor: Colors.APP_COLOR_BORDER, borderBottomWidth: 1, paddingLeft: 0, paddingRight: 0, minHeight: 40 }} >
                        <View style={{ flex: 1, padding: 10, backgroundColor: 'white', }}>
                            {/* <Text style={styles.commentText}>{this.getValue(subItem).trim()}</Text> */}
                            {this.getValue(subItem)}

                        </View>
                    </DataTable.Row>
                )
            }

            if (item.numberOfColumns == 4) {
                return (

                    <View key={idx} style={{ flex: 1, backgroundColor: 'white' }}>

                        <DataTable.Row key={idx} style={{ backgroundColor: 'white', borderBottomWidth: 0, paddingLeft: 15, paddingRight: 15, minHeight: 40 }} >

                            <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between', paddingRight: 10, paddingTop: 10, }}>
                                <View style={{}}>
                                    <Text style={styles.textTitleStyle}>{subItem[0].rowTitle}</Text>
                                </View>
                                <View style={{ flex: 1, alignItems: 'flex-end' }}>
                                    <Text style={styles.textTitleValueStyle}>{this.getValue(subItem[0])}</Text>
                                </View>
                            </View>
                            <View style={{ width: 2, backgroundColor: Colors.APP_COLOR_BORDER, }} />

                            <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between', paddingLeft: 10, paddingTop: 10 }}>
                                <View style={{ flex: 1, }}>
                                    <Text style={styles.textTitleStyle}>{subItem[1].rowTitle}</Text>
                                </View>
                                <View style={{}}>
                                    <Text style={styles.textTitleValueStyle}>{this.getValue(subItem[1])}</Text>
                                </View>
                            </View>
                        </DataTable.Row>
                    </View>
                )
            }

            return (

                <DataTable.Row key={idx} style={{ backgroundColor: 'white', borderBottomWidth: 0, paddingLeft: 15, paddingRight: 15, minHeight: 40 }} >

                    <View style={{ flex: 1, flexDirection: 'row' }}>

                        <View style={{ flex: 1, justifyContent: 'center' }}>
                            <Text style={styles.textTitleStyle} >{subItem[0].rowTitle}</Text>
                        </View>
                        {/* <View style={{ width: 2, backgroundColor: Colors.APP_COLOR_BORDER, }} /> */}

                        <View style={{ width: 100, justifyContent: 'center', alignItems: 'flex-end', paddingRight: 10 }}>
                            <Text style={styles.textTitleValueStyle} >{this.getValueForMultipleRows(subItem[0], 1)}</Text>
                        </View>
                        <View style={{ width: 2, backgroundColor: Colors.APP_COLOR_BORDER, }} />

                        <View style={{ width: 100, justifyContent: 'center', alignItems: 'flex-end', paddingRight: 10 }}>
                            <Text style={styles.textTitleValueStyle} >{this.getValueForMultipleRows(subItem[0], 2)}</Text>
                        </View>

                    </View>


                    <View style={{ width: 2, backgroundColor: Colors.APP_COLOR_BORDER, }} />

                    <View style={{ flex: 1, flexDirection: 'row' }}>
                        <View style={{ flex: 1, justifyContent: 'center', paddingLeft: 10 }}>
                            <Text style={styles.textTitleStyle} >{subItem[1].rowTitle}</Text>
                        </View>
                        {/* <View style={{ width: 2, backgroundColor: Colors.APP_COLOR_BORDER, }} /> */}

                        <View style={{ width: 100, justifyContent: 'center', alignItems: 'flex-end', paddingRight: 10 }}>
                            <Text style={styles.textTitleValueStyle} >{this.getValueForMultipleRows(subItem[1], 1)}</Text>
                        </View>
                        <View style={{ width: 2, backgroundColor: Colors.APP_COLOR_BORDER, }} />

                        <View style={{ width: 100, justifyContent: 'center', alignItems: 'flex-end' }}>
                            <Text style={styles.textTitleValueStyle} >{this.getValueForMultipleRows(subItem[1], 2)}</Text>
                        </View>
                    </View>
                </DataTable.Row>
            )

        });
        return jsx
    }

    getHeaderTitle = (item, index = 0) => {

        const { stockDetailObj } = this.state

        if (item.categoryName == 'Index1') {
            return stockDetailObj.stock_details[0][item.rowKey[index]] || 'N/A'
        } else {

            if (item.categoryChangeWithKey) {
                if (item.categoryChangeWithKey == 'date') {
                    return item.categoryName.replace("%d", stockDetailObj.stock_details[0].fmt_trd_dt);
                }
            }
            return item.categoryName
        }
    }

    renderCell = (item) => {
        return (
            <View key={'cellView'} style={styles.cellView}>
                <View style={{ flex: 1 }}>
                    <View key={'cellTitleView'} style={styles.cellTitleView}>
                        <View style={{ flex: 1 }}>
                            <Text numberOfLines={2} key={'cellTitleText'} style={styles.cellTitleText}>{this.getHeaderTitle(item)}</Text>
                        </View>
                        {item.numberOfRow != undefined &&
                            <Text numberOfLines={2} key={'cellSubTitleText'} style={styles.cellSubTitleText}>{this.getHeaderTitle(item, 1)}</Text>
                        }
                    </View>
                    {this.renderBody(item)}
                </View>
            </View>
        )
    }





    renderItem = () => {

        if (this.state.stockDetailObj.stock_details !== undefined) {
            return (
                <View key={'flatListView'} style={styles.flatListView}>
                    <FlatList
                        data={CONTENT}
                        renderItem={({ item, }) => (
                            this.renderCell(item)
                        )}
                        keyExtractor={item => item.id}
                    />
                    <Text style={styles.stockInfo}>{Constant.AppText.stockDetailWebInfo}</Text>
                </View>
            )
        }
    }

    render() {

        return (
            <View style={{ flex: 1, }}>
                {this.renderItem()}

                {this.state.isLoading && <Loader />}
            </View>
        );
    }
}



const mapStateToProps = (state) => {
    return {
        favList: state.stockOperationReducers.favList,
    }
}

const mapDispatchToProps = (dispatch) => {

    return {
        favStockDetails: (request) => dispatch(favStockDetails(request)),
    }
}



export default connect(mapStateToProps, mapDispatchToProps)(StockDetailsSection);


const styles = StyleSheet.create({

    mainContainer: {
        flex: 1,
        backgroundColor: Colors.APP_COLOR_BACKGROUND
    },

    stockNameView: {
        justifyContent: 'center',
        padding: 10
    },

    stockNameText: {
        fontFamily: Constant.AppFonts.Roboto.Bold,
        color: Colors.APP_DARK_GARY,
        fontSize: 18,
        fontWeight: Constant.AppFontWeight.BOLD,
    },

    flatListView: {
        flex: 1,
        backgroundColor: Colors.APP_COLOR_BACKGROUND
    },

    cellView: {
        flex: 1,
        marginBottom: 14
    },

    cellTitleView: {
        justifyContent: 'space-between',
        flexDirection: 'row',
        height: 45,
        // backgroundColor:'red',
        alignItems: 'center',
    },

    cellTitleText: {
        fontFamily: Constant.AppFonts.Roboto.Medium,
        color: Colors.APP_COLOR1,
        fontSize: 17,
        fontWeight: Constant.AppFontWeight.BOLD,
    },

    cellSubTitleText: {
        fontFamily: Constant.AppFonts.Roboto.Medium,
        color: Colors.MAIN_TITLE_COLOR,
        fontSize: 15,
        fontWeight: Constant.AppFontWeight.MEDIUM,
    },


    textTitleStyle: {
        color: Colors.APP_DARK_GARY,
        fontFamily: Constant.AppFonts.Roboto.Regular,
        fontWeight: Constant.AppFontWeight.NORMAL,
        fontSize: (13)
    },

    textTitleValueStyle: {
        color: Colors.MAIN_TITLE_COLOR,
        fontFamily: Constant.AppFonts.Roboto.Bold,
        fontSize: (13),
        fontWeight: Constant.AppFontWeight.BOLD,
    },

    commentText: {
        color: Colors.APP_DARK_GARY,
        fontFamily: Constant.AppFonts.Roboto.Regular,
        fontWeight: Constant.AppFontWeight.NORMAL,
        fontSize: (13)
    },

    stockInfo: {

        marginTop: 30,
        color: Colors.APP_DARK_GARY,
        fontFamily: Constant.AppFonts.Roboto.Medium,
        fontWeight: Constant.AppFontWeight.MEDIUM,
        lineHeight: 30,
        fontSize: 14
    }

});
