import AsyncStorage from '@react-native-async-storage/async-storage';

const MEMORY_KEY_PREFIX = '@MyStorage:';
let dataMemory = {};

/** @class */
export default class MyStorage {

    /**
     * This is used to set a specific item in storage
     */
    static setItem(key, value) {

        var valueString = value        
        if (!isNaN(valueString)) {
            valueString = String(value)
        }

        AsyncStorage.setItem(key, valueString);
        dataMemory[key] = value;
        return dataMemory[key];
    }

    /**
     * This is used to get a specific key from storage
     */
    static getItem(key) {
        return Object.prototype.hasOwnProperty.call(dataMemory, key) ? dataMemory[key] : '';
    }

    /**
     * This is used to remove an item from storage
     */
    static removeItem(key) {
        AsyncStorage.removeItem(key);
        return delete dataMemory[key];
    }

    /**
     * This is used to clear the storage
     */
    static clear() {
        AsyncStorage.clear();
        dataMemory = {};
        return dataMemory;
    }

    /**
     * Will sync from AsyncStorage to MemoryStorage
     */
    static sync() {
        
        AsyncStorage.getAllKeys((errKeys, keys) => {
            if (errKeys) rej(errKeys);
            // const memoryKeys = keys.filter((key) => key.startsWith(MEMORY_KEY_PREFIX));
            AsyncStorage.multiGet(keys, (err, stores) => {

                if (err) rej(err);
                stores.map((result, index, store) => {
                    const key = store[index][0];
                    const value = store[index][1];
                    const memoryKey = key.replace(MEMORY_KEY_PREFIX, '');
                    dataMemory[memoryKey] = value;
                    console.log('Storage Value', value,memoryKey );
                });
            });
            
        });
    }
}
