import * as React from 'react';
import {
  Button,
  View,
  Text,
  TouchableOpacity,
  Image,
  Platform
} from 'react-native';

import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { createDrawerNavigator } from '@react-navigation/drawer';

import Feather from 'react-native-vector-icons/Feather';
import Ionicons from 'react-native-vector-icons/Ionicons';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons';

import MainContainer from '../screens/Containers/commonContainers/MainContainer';
import ExpendableDrawer from './ExpendableDrawer';
import StockDetailsContainer from '../screens/Containers/WebContainers/StockDetailsContainer';
import Constant from '../screens/Utils/Constant';
import CustomSidebarMenu from './webSideMenu/CustomSidebarMenu';
import AccountContainer from '../screens/Containers/WebContainers/AccountContainer';
import ChangeEmail from '../screens/Components/WebComponent/AccountManager/ChangeEmail.web';
import ChangePassword from '../screens/Components/WebComponent/AccountManager/ChangePassword.web';
import Login from '../screens/Components/WebComponent/AccountManager/Login.web';
import ForgetPassword from '../screens/Components/WebComponent/AccountManager/ForgetPassword.web';
import RePaymentContainer from '../screens/Containers/WebContainers/RePaymentContainer';
import WebTwitterFeed from '../screens/Containers/WebContainers/WebTwitterFeed';
import SearchWeb from '../screens/Containers/WebContainers/SearchWeb';

import SubscriptionHelp from '../screens/Components/Mobile/Auth/SubscriptionHelp';




import * as Colors from '../screens/Utils/Colors';

import { fontSizeScale } from '../screens/Services/AppManager';
import { useSelector, useDispatch } from 'react-redux';
import { changeDrawerToggle, changeSideMenuIndex } from '../screens/Services/Redux/actions/stocksAction';
import MyStorage from '../screens/Services/MyStorage';

const Stack = createStackNavigator();
const Drawer = createDrawerNavigator();


function NavigationDrawerStructure({ props }) {

  const dispatch = useDispatch()
  const toggleDrawer = () => {
    dispatch(changeDrawerToggle())

  }
  return (
    <View style={{ width: 100, height: 40, justifyContent: 'center', alignItems: 'center' }}>
      <TouchableOpacity style={{ backgroundColor: '#e6f1fe', width: 50, height: 40, justifyContent: 'center', alignItems: 'center', borderColor: '#cee2fd', borderWidth: 2, borderRadius: 3 }} onPress={toggleDrawer}>
        <Feather name={'menu'} size={fontSizeScale(25)} color={Colors.APP_COLOR1} />
      </TouchableOpacity>
    </View>
  );
}


const NavigationBackButton = (props) => {
  const toggleDrawer = () => {
    props.navigationProps.goBack();
  };

  return (
    <View style={{ width: 100, height: 40, justifyContent: 'center', alignItems: 'center' }}>
      <TouchableOpacity style={{ backgroundColor: '#e6f1fe', width: 50, height: 40, justifyContent: 'center', alignItems: 'center', borderColor: '#cee2fd', borderWidth: 2, borderRadius: 3 }} onPress={toggleDrawer}>
        <Ionicons name={'arrow-back-sharp'} size={fontSizeScale(25)} color={Colors.APP_COLOR1} />
      </TouchableOpacity>
    </View>
  );
}


const NavigationRightButton = (props) => {

  const dispatch = useDispatch();
  const reduxState = useSelector(state => state)['stockOperationReducers'];


  const toggleDrawer = () => {

    let status = MyStorage.getItem(Constant.ShareInstanceKey.kPaymentStatus)
    let newIndex = (parseInt(status) == 1 && reduxState.favList.length > 0) ? 4 : 3
    dispatch(changeSideMenuIndex(newIndex, 0));

    props.navigationProps.navigate('authScreen');
  };

  let isLogin = MyStorage.getItem(Constant.ShareInstanceKey.kUserID)
  var text = 'Manage Subscription'
  if (isLogin != '') {
    text = MyStorage.getItem(Constant.ShareInstanceKey.kEmailId)
  }
  return (
    <View style={{ height: 40, justifyContent: 'center', alignItems: 'center', marginRight: 10 }}>
      <TouchableOpacity style={{ height: 40, flexDirection: 'row', justifyContent: 'center', alignItems: 'center', }} onPress={toggleDrawer}>
        <Text style={{ fontFamily: Constant.AppFonts.Roboto.Bold, fontWeight: Constant.AppFontWeight.BOLD, size: 20, color: Colors.MAIN_TITLE_COLOR }}>{text}</Text>
        {/* <MaterialIcons style={{ marginLeft: 20 }} name={'keyboard-arrow-down'} size={20} color={Colors.APP_DARK_GARY} /> */}

      </TouchableOpacity>
    </View>
  );
}


function stocksScreenStack({ navigation }) {
  return (
    <Stack.Navigator initialRouteName="Stocks">

      <Stack.Screen
        name="Stocks"
        component={MainContainer}

        options={({ navigation, route }) => ({
          title: Constant.AppText.appTitle + ' | ' + 'Stocks',
          headerTitle: '',
          headerTitleAlign: 'left',
          headerTitleStyle: {
            marginLeft: -20,
            textAlign: 'left',
            fontFamily: Constant.AppFonts.Roboto.Medium,
            fontWeight: Constant.AppFontWeight.MEDIUM,
          },

          headerLeft: () =>
            <NavigationDrawerStructure
              navigationProps={navigation}
            />,

          headerRight: () =>
            <NavigationRightButton
              navigationProps={navigation}
            />,


          headerStyle: {
            backgroundColor: 'white',
            shadowColor: '#0f75f6',
            elevation: 0,
            borderBottomWidth: 2,
            borderBottomColor: Colors.APP_COLOR_BORDER
          },
        })}
      />

      <Stack.Screen
        name="StockDetailsContainer"
        component={StockDetailsContainer}
        options={({ navigation, route }) => ({

          title: Constant.AppText.appTitle + ' | ' + 'Stock Details',
          headerTitle: '',

          headerLeft: () =>
            <NavigationBackButton
              navigationProps={navigation}
            />,

          headerStyle: {
            backgroundColor: 'white',
            shadowColor: '#0f75f6',
            elevation: 0,
            borderBottomWidth: 2,
            borderBottomColor: Colors.APP_COLOR_BORDER
          },
        })}
      />

      <Stack.Screen
        name="WebTwitterFeed"
        component={WebTwitterFeed}
        options={({ navigation, route }) => ({

          title: Constant.AppText.appTitle + ' | ' + 'Tweets',
          headerTitle: '',

          headerLeft: () =>
            <NavigationBackButton
              navigationProps={navigation}
            />,

          headerStyle: {
            backgroundColor: 'white',
            shadowColor: '#0f75f6',
            elevation: 0,
            borderBottomWidth: 2,
            borderBottomColor: Colors.APP_COLOR_BORDER
          },
        })}
      />

      <Stack.Screen
        name="SearchWeb"
        component={SearchWeb}
        options={({ navigation, route }) => ({

          title: Constant.AppText.appTitle + ' | ' + 'Search',
          headerTitle: '',

          headerLeft: () =>
            <NavigationBackButton
              navigationProps={navigation}
            />,

          headerStyle: {
            backgroundColor: 'white',
            shadowColor: '#0f75f6',
            elevation: 0,
            borderBottomWidth: 2,
            borderBottomColor: Colors.APP_COLOR_BORDER
          },
        })}
      />






    </Stack.Navigator>
  );
}

function authScreenStack({ navigation }) {

  return (
    <Stack.Navigator initialRouteName={'AccountContainer'}>


      <Stack.Screen
        name="ForgetPassword"
        component={ForgetPassword}
        options={({ navigation, route }) => ({

          title: Constant.AppText.appTitle + ' | ' + 'Forgot Password',
          headerTitle: '',
          headerLeft: () =>
            <NavigationBackButton
              navigationProps={navigation}
            />,

          headerStyle: {
            backgroundColor: 'white',
            shadowColor: '#0f75f6',
            elevation: 0,
            borderBottomWidth: 2,
            borderBottomColor: Colors.APP_COLOR_BORDER
          },
        })}
      />

      <Stack.Screen
        name="ChangeEmail"
        component={ChangeEmail}
        options={({ navigation, route }) => ({

          title: Constant.AppText.appTitle + ' | ' + 'Change Email',
          headerTitle: '',
          headerLeft: () =>
            <NavigationBackButton
              navigationProps={navigation}
            />,

          headerStyle: {
            backgroundColor: 'white',
            shadowColor: '#0f75f6',
            elevation: 0,
            borderBottomWidth: 2,
            borderBottomColor: Colors.APP_COLOR_BORDER
          },
        })}
      />

      <Stack.Screen
        name="ChangePassword"
        component={ChangePassword}
        options={({ navigation, route }) => ({

          title: Constant.AppText.appTitle + ' | ' + 'Change Password',
          headerTitle: '',

          headerLeft: () =>
            <NavigationBackButton
              navigationProps={navigation}
            />,

          headerStyle: {
            backgroundColor: 'white',
            shadowColor: '#0f75f6',
            elevation: 0,
            borderBottomWidth: 2,
            borderBottomColor: Colors.APP_COLOR_BORDER
          },
        })}
      />

      <Stack.Screen
        name="Login"
        component={Login}
        options={({ navigation, route }) => ({

          title: Constant.AppText.appTitle + ' | ' + 'Login',
          headerTitle: '',

          headerLeft: () =>
            <NavigationBackButton
              navigationProps={navigation}
            />,

          headerStyle: {
            backgroundColor: 'white',
            shadowColor: '#0f75f6',
            elevation: 0,
            borderBottomWidth: 2,
            borderBottomColor: Colors.APP_COLOR_BORDER
          },
        })}
      />

      <Stack.Screen
        name="RePaymentContainer"
        component={RePaymentContainer}
        options={({ navigation, route }) => ({

          title: Constant.AppText.appTitle + ' | ' + 'Manage Subscription',
          headerTitle: '',

          headerLeft: () =>
            <NavigationBackButton
              navigationProps={navigation}
            />,

          headerStyle: {
            backgroundColor: 'white',
            shadowColor: '#0f75f6',
            elevation: 0,
            borderBottomWidth: 2,
            borderBottomColor: Colors.APP_COLOR_BORDER
          },
        })}
      />

      <Stack.Screen
        name="AccountContainer"
        component={AccountContainer}
        options={({ navigation, route }) => ({

          title: Constant.AppText.appTitle + ' | ' + 'Manage Subscription',
          headerTitle: '',

          headerLeft: () =>
            <NavigationDrawerStructure
              navigationProps={navigation}
            />,

          headerRight: () =>
            <NavigationRightButton
              navigationProps={navigation}
            />,

          headerStyle: {
            backgroundColor: 'white',
            shadowColor: '#0f75f6',
            elevation: 0,
            borderBottomWidth: 2,
            borderBottomColor: Colors.APP_COLOR_BORDER
          },
        })}
      />

      <Stack.Screen
        name="SubscriptionHelp"
        component={SubscriptionHelp}
        options={({ navigation, route }) => ({

          title: Constant.AppText.appTitle + ' | ' + 'Help',
          headerTitle: '',

          headerLeft: () =>
            <NavigationBackButton
              navigationProps={navigation}
            />,

          headerRight: () =>
            <NavigationRightButton
              navigationProps={navigation}
            />,

          headerStyle: {
            backgroundColor: 'white',
            shadowColor: '#0f75f6',
            elevation: 0,
            borderBottomWidth: 2,
            borderBottomColor: Colors.APP_COLOR_BORDER
          },
        })}
      />


    </Stack.Navigator>
  );
}

function App() {

  const reduxState = useSelector(state => state)['stocksReducers'];

  return (
    <NavigationContainer linking={{ enabled: false }}>

      <Drawer.Navigator
        drawerType='permanent'
        drawerContent={props => reduxState.drawerToggle ? <ExpendableDrawer props={props} /> : <CustomSidebarMenu props={props} />}
        drawerStyle={{
          width: reduxState.drawerToggle ? 260 : 80,
          borderRightWidth: 0
        }}
      >
        <Drawer.Screen
          name="stocksScreen"
          component={stocksScreenStack} />


        <Drawer.Screen
          name="authScreen"
          component={authScreenStack} />


      </Drawer.Navigator>
    </NavigationContainer>
  );
}

export default App;