
import * as React from 'react';
import { View, Text, StyleSheet, Platform, TouchableOpacity, ScrollView, TextInput } from 'react-native';
import Constant from '../../../Utils/Constant';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons'
import * as Colors from '../../../Utils/Colors';
import { showFlashBanner } from '../../../Utils/FlashMessage';
import { checkValidation } from '../../../Services/AppManager';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import { PayPalButton } from "react-paypal-button-v2";
import { ActivityIndicator, Button, HelperText } from 'react-native-paper';
import MyStorage from '../../../Services/MyStorage';
import ApiManager from '../../../Services/Networking/ApiManager';
import CircularProgress from '@material-ui/core/CircularProgress';



let apiManager = ApiManager.getInstance()



class PaypalPopup extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isPayPalBtnLoaded: false,
            enterdEmail: '',
            isValidEmail: false,
            errorMessage: '',
            isApiCalling: false
        }
    }

    componentDidMount() {
        this.setState({
            enterdEmail: MyStorage.getItem(Constant.ShareInstanceKey.kEmailId) || '',
            isValidEmail: MyStorage.getItem(Constant.ShareInstanceKey.kEmailId) != ''
        })
    }


    verifyEmail = () => {

        let emailStatus = checkValidation('email', this.state.enterdEmail, 'Email')
        if (!emailStatus.status) {
            this.setState({
                errorMessage: emailStatus.messsage
            })
            return;
        }

        this.setState({
            isApiCalling: true
        })
        let dict = {
            email: this.state.enterdEmail
        }

        apiManager.onPostApi(Constant.EndPoint.checkedEmail, dict).then((responce) => {

            // console.log(responce);

            if (responce.json.status == 401) {
                this.setState({
                    errorMessage: 'The email address you have entered is already registered.',
                    isApiCalling: false

                })
            } else {
                this.setState({
                    errorMessage: '',
                    isValidEmail: true,
                    isApiCalling: false
                })
            }

        }).catch((error) => {
            console.log(error.msg);
        })

    }

    render() {

        // const onSuccess = (data, actions) => {
        //     actions.order.capture().then((details) => {
        //         let email = this.state.enterdEmail != '' ? this.state.enterdEmail : details.payer.email_address
        //         this.props.onSuccess(details.purchase_units[0].payments.captures[0].id, email)
        //     })
        // }

        const createOrder = (data, actions) => {
            return actions.order.create({
                purchase_units: [{
                    amount: {
                        currency_code: "USD",
                        value: this.props.btnInfo.amount
                    }
                }],
            });

        }

        const createSubscription = (data, actions) => {
            return actions.subscription.create({
                plan_id: this.props.btnInfo.subscriptionID  // 'P-20133533GR6998223MCPFROA'
            });
        }

        const onSuccess = (data, actions) => {

            actions.subscription.get().then((details) => {
                // console.log(details);
                let email = this.state.enterdEmail != '' ? this.state.enterdEmail : details.subscriber.email_address
                this.props.onSuccess(details.id, email)
            });
        }

        let email = MyStorage.getItem(Constant.ShareInstanceKey.kEmailId)

        return (
            <View style={{}}>

                <Dialog onClose={() => this.props.onClosePopup()} aria-labelledby="customized-dialog-title" open={true}>
                    <DialogTitle id="customized-dialog-title">{this.props.btnInfo.headerTitle + ' ' + this.props.btnInfo.headerSubTitle}</DialogTitle>

                    <DialogContent>
                        <View style={{ flex: 1, marginBottom: 10, }}>
                            <TextInput
                                placeholder={'Email'}
                                style={styles.Body_Input}
                                onChangeText={(text) => this.setState({ enterdEmail: text, errorMessage: '', isValidEmail: false })}
                                value={this.state.enterdEmail}
                                editable={email == '' ? true : false}

                            />
                            {email == '' &&
                                <HelperText padding={'none'} style={[styles.errorMessage, { color: this.state.isValidEmail ? 'green' : 'red' }]} visible={(this.state.errorMessage != '') || this.state.isValidEmail}>
                                    {this.state.isValidEmail ? 'Email address is available for creating a new account, You can pay now.' : this.state.errorMessage}
                                </HelperText>
                            }

                        </View>

                        <DialogContentText style={{ fontSize: 12 }}>
                            {email == '' ? Constant.AppText.paypalLoginInfoText : ''}
                        </DialogContentText>
                    </DialogContent>

                    <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', marginBottom: 20 }}>

                        <View style={{ width: 200, justifyContent: 'center' }}>
                            {((!this.state.isPayPalBtnLoaded && this.state.isValidEmail) || (this.state.isApiCalling)) &&
                                // <Text style={{ alignSelf: 'center', fontSize: 15, fontFamily: Constant.AppFonts.Roboto.Bold, fontWeight: Constant.AppFontWeight.BOLD, }}>Loading....</Text>
                                <ActivityIndicator size={'small'} color={Colors.APP_COLOR1} style={{ alignSelf: 'center' }} />
                            }

                            {this.state.isValidEmail &&

                                <PayPalButton
                                    options={{
                                        vault: true,
                                        clientId: 'ARL8IOrT5pkWzMrFo2aS1ES8jo3WCUfHkv_9uBmm1FHttNerX-4vjT37gDQoCNrxs5HptOae-FOuinIE',
                                        disableFunding: 'credit,card',
                                        intent: 'subscription'
                                    }}
                                    createSubscription={createSubscription}
                                    onApprove={onSuccess}
                                    onButtonReady={() => this.setState({ isPayPalBtnLoaded: TextTrackCueList })}
                                    onError={(error) => {
                                        showFlashBanner(error.err, '1')
                                    }}

                                    catchError={(error) => {
                                        showFlashBanner(error.err, '1')
                                    }}

                                />
                            }
                        </View>

                        {!this.state.isValidEmail && !this.state.isApiCalling &&

                            <View style={styles.emailButtonView}>
                                <Button onPress={() => this.verifyEmail()} mode="Text" color="white" style={styles.emailButton}> SUBMIT </Button>
                            </View>
                        }

                        {email == '' &&
                            <View style={{ width: 60, position: 'absolute', alignSelf: 'flex-end', bottom: 15, marginRight: 10, }}>
                                <Text onPress={() => this.props.onLogin()} style={{ color: Colors.APP_COLOR1, fontSize: 15, paddingLeft: 5, textDecorationLine: 'underline', fontFamily: Constant.AppFonts.Roboto.MediumItalic, fontWeight: Constant.AppFontWeight.SEMI_BOLD, fontStyle: 'italic' }}>Log In</Text>
                            </View>
                        }
                    </View>
                </Dialog>

            </View>

        );
    }
}




export default PaypalPopup;

const styles = StyleSheet.create({

    Container: {
        width: 340,
        height: 340,
        backgroundColor: 'white',
        borderRadius: 10,
    },
    Body: {
        paddingHorizontal: 20,
        paddingVertical: 20
    },
    Body_Text: {
        fontSize: 25,
        paddingTop: 15,
        fontFamily: Constant.AppFonts.Roboto.Medium,
        fontWeight: Constant.AppFontWeight.MEDIUM,
    },
    Body_Input: {
        borderWidth: 1.5,
        paddingVertical: 10,
        paddingHorizontal: 10,
        marginTop: 15,
        borderRadius: 5,
        borderColor: 'rgba(0,0,0,0.2)',
        fontFamily: Constant.AppFonts.Roboto.Medium,
        fontWeight: Constant.AppFontWeight.MEDIUM,

    },
    Container_Button: {
        backgroundColor: '#007AFF',
        alignItems: 'center',
        flexDirection: 'row',
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 10
    },
    Button_1: {
        backgroundColor: '#DCDCDC',
        width: '50%',
        alignItems: 'center',
        paddingVertical: 15,
        borderBottomLeftRadius: 5,

    },
    Button_2: {
        backgroundColor: '#007AFF',
        width: '50%',
        alignItems: 'center',
        paddingVertical: 15,
        borderBottomRightRadius: 5,


    },
    ButtonText_1: {

        fontSize: 13,
        fontFamily: Constant.AppFonts.Roboto.Medium,
        fontWeight: Constant.AppFontWeight.MEDIUM,

    },
    ButtonText_2: {
        color: 'white',
        fontSize: 13,
        fontFamily: Constant.AppFonts.Roboto.Medium,
        fontWeight: Constant.AppFontWeight.MEDIUM,

    },

    emailButtonView: {
        alignItems: 'center',
        justifyContent: 'center',
        height: 40,
        width: 200,
    },

    emailButton: {

        height: 40,
        width: 200,
        backgroundColor: '#007AFF',
        alignItems: 'center',
        borderRadius: 20,
        fontFamily: Constant.AppFonts.Roboto.Medium,
        fontWeight: Constant.AppFontWeight.MEDIUM,
    },

    errorMessage: {
        color: 13,
        fontFamily: Constant.AppFonts.Roboto.Medium,
        fontWeight: Constant.AppFontWeight.MEDIUM,
    }

});